import React from "react";
import "./App.css";
import styled from "styled-components";
import { Login } from "./login";
//import './components/login-video';

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
`;

window.name = "not-logged";

function App() {
  return (
    <AppContainer>
        <Login />
    </AppContainer>
  );
}

export default App;
