import { Button } from "baseui/button";
import { Input } from "baseui/input";
import styled from "styled-components";
import {
  HeadingXXLarge,
  HeadingXLarge,
  HeadingLarge,
  HeadingMedium,
  HeadingSmall,
  HeadingXSmall,
} from "baseui/typography";
import {
  Container,
  ErrorText,
  InnerContainer,
  InputWrapper,
  StyledInput,
} from "./components/commons";

import "./login.css";

import { useSignIn } from "react-auth-kit";
import { useFormik } from "formik";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {useIsAuthenticated} from 'react-auth-kit';
import { useSignOut } from "react-auth-kit";

import Simulator from "./Simulator.js";
import SimulatorIframe from "./SimulatorIframe.js";

import $ from "jquery";

function Login(props: any) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();
  const singOut = useSignOut();
  const navigate = useNavigate();

  const logout = () => {
    singOut();
    window.open(window.location.origin + "/", "_self");
    //navigate("/");
  };

  const onSubmit = async (values: any) => {
    setError("");

    try {

      const response = await axios.post(
        "https://api.aistenlab.com/auth/login",
        values
      );

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user_type", response.data.user.user_type);
      localStorage.setItem("user_id", response.data.user.id);

      $("#loading").css("display", "flex");
      localStorage.setItem("logged", "1");

      signIn({
        token: response.data.token,
        expiresIn: 360000,
        tokenType: "Bearer",
        authState: { email: values.email },
      });

      //window.open(window.location.origin + "/", "_self");
      //setSuccess(true);
    } catch (err) {
      if (err && err instanceof AxiosError)
        setError(err.response?.data.message);
      else if (err && err instanceof Error) setError(err.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit,
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var is_iframe = urlParams.get('i');

  if(is_iframe){
    window.is_iframe = true;
  }else{
    window.is_iframe = false;
  }

  if(!window.checkedLog && isAuthenticated()){
    window.checkedLog = true;
    $.ajax({
      url: 'https://api.aistenlab.com/simulator-projects/quota',
      type: 'GET',
      headers: {
        "Authorization": 'Bearer ' + localStorage.getItem("token")
      },
      success: function (data) {
      },
      error: function (err) {
        if(isAuthenticated()){
          //localStorage.clear();
          if(localStorage.getItem("logged") == "1"){
            localStorage.setItem("logged", "0");
            localStorage.setItem("token", "");
            localStorage.setItem("user_type", "");
            localStorage.setItem("user_id", "");
            singOut();
            
            setTimeout(function () {
              window.open(window.location.origin + "/", "_self");
            }, 1000);
          }
        }
      }
    })
  }

  if(!isAuthenticated()){
    $("#loading").css("display", "none");
  }
  

  return (
    <>
      
      {is_iframe ? (
        <SimulatorIframe/>
      ) : 
      isAuthenticated() ? (
        <Simulator/>
      ) : (

        <div className="Login">
          <div className="content">
          <div className="flex-div">
            <div className="name-content">
              <h1 className="logo">Aisten</h1>
              <p>Create beautiful presentations with our 3D Simulator.</p>
            </div>
              <form onSubmit={formik.handleSubmit}>
                <input value={formik.values.email} onChange={formik.handleChange} name="email" type="email" required placeholder="Email"></input>
                <input value={formik.values.password} onChange={formik.handleChange} name="password" type="password" required placeholder="Password"></input>
                <Button className="login" size="large" kind="primary" isLoading={formik.isSubmitting}>
                        Log In
                </Button>
              </form>
          </div>
        </div>
        </div>
      )}
        </>
  );
}

export { Login };
