import React, { useState, useEffect } from 'react';
import './Annotation.scss';
import __ from './../utils/translation';
import $ from "jquery";
import { downloadImage, downloadSVG } from '../utils/downloadImage';
import { applyZoom, zoomWithKeys, zoomWithMouse, zoomOptions } from '../utils/zoom';
import { ReactComponent as IconDownload2 } from '../icons/file_download.svg';

const RenderState = ({ canvas }) => {

    return (
        <>
            <p className="title">{__('RENDER')}</p>

            <div id="options-general">
                <div className='option-general'>
                    <span id="txt-render">Ao clicar no botão abaixo, será gerada uma imagem renderizada e mais 
                        realista do que está aparecendo na tela. Você pode rodar e dar zoom no local de 
                        destaque desejado antes de clicar no botão de renderizar para evitar travamentos. 
                        Esse processo leva tempo (5min a 60min dependendo da máquina), espere até que verificar 
                        que a imagem está na qualidade desejada.
                    </span>
                </div>
                <div className='option-general'>
                    <button id="modo-realista">Renderizar</button>
                </div>
            </div>
            
        </>
    )
}

export default RenderState