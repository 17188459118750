import React, { useState, useEffect } from 'react';
import delayedAction from './../utils/delayedAction';
import __ from './../utils/translation';
import { ChromePicker } from 'react-color';
import InputAmount from './InputAmount';
import { InputSelect, Option } from './InputSelect';

import { fabric } from 'fabric';
import $ from "jquery";


const SelectionBorderSettings = ({ canvas, activeSelection }) => {

  const [border, setBorder] = useState({
    width: 0,
    style: { strokeDashArray: [], strokeLineCap: 'square' },
    corner: '',
    color: 'rgba(0,0,0,1)'
  })
  const [borderColorPicker, setBorderColorPicker] = useState(false)



  // load selected object's border settings on panel load
  useEffect(() => {
    let loadedBorder = {
      width: activeSelection.strokeWidth,
      style: {
        strokeDashArray: activeSelection.strokeDashArray,
        strokeLineCap: activeSelection.strokeLineCap
      },
      corner: activeSelection.strokeLineJoin,
      color: activeSelection.stroke
    }

    //if (!activeSelection.fill.colorStops) {
    let color = new fabric.Color(activeSelection.stroke);
    //setColor(`rgba(${colorRgba[0]}, ${colorRgba[1]}, ${colorRgba[2]}, ${colorRgba[3]})`)
    $("#colorPickerBorder").val(rgbToHex(color._source[0], color._source[1], color._source[2]));
    //}

    setBorder(loadedBorder)

    /*var cloned = document.getElementById("list-colors").cloneNode(true);

    //document.getElementById("my-colors-border").appendChild(cloned);
    $("#my-colors-border #list-colors").css("position", "absolute");
    $("#my-colors-border #list-colors").css("z-index", "100");
    $("#my-colors-border #list-colors").css("margin-top", "-10px");
    $("#my-colors-border #list-colors").css("display", "none");
    $("#my-colors-border #list-colors div").css("border", "none");*/
  }, [activeSelection])

  $("body").on('click', '#my-colors-border #list-colors div', function (evt) {
    setBorder({...border,
      color: `rgba(${$(this).data("r")}, ${$(this).data("g")}, ${$(this).data("b")}, ${1})`
    })

    canvas.getActiveObjects().forEach(obj => {
      obj.set('strokeStyle', `rgba(${$(this).data("r")}, ${$(this).data("g")}, ${$(this).data("b")}, ${1})`)
      obj.set('stroke', `rgba(${$(this).data("r")}, ${$(this).data("g")}, ${$(this).data("b")}, ${1})`)
    })

    $("#colorPickerBorder").val(rgbToHex($(this).data("r"), $(this).data("g"), $(this).data("b")));

    canvas.renderAll();
    canvas.trigger('object:modified')
    $(".holder-color-border").val($("#colorPickerBorder").val());
  });

  const handleBorderWidthChange = (action, amount) => {
    let width = 0

    if (action === 'decrease') {
      width = border.width
      width = width === 0 ? 0 : width - 1
    }

    if (action === 'increase') {
      width = border.width + 1
    }

    if (action === 'change') {
      width = parseInt(amount)
      if (!Number.isInteger(width)) return
    }

    setBorder({...border,
      width: width
    })

    canvas.getActiveObjects().forEach(obj => {
      obj.set({
        strokeUniform: true,
        strokeWidth: width
      })
    })
    canvas.renderAll()
    canvas.trigger('object:modified')
  }


  const handleBorderStyleChange = (style) => {
    setBorder({...border,
      style: style
    })

    canvas.getActiveObjects().forEach(obj => {
      obj.set({
        strokeUniform: true,
        strokeDashArray: style.strokeDashArray,
        strokeLineCap: style.strokeLineCap
      })
    })
    canvas.renderAll()
    canvas.trigger('object:modified')
  }


  const handleBorderCornerChange = (corner) => {
    setBorder({...border,
      corner: corner
    })

    activeSelection.set('strokeLineJoin', corner)
    canvas.renderAll()
    canvas.trigger('object:modified')
  }


  const handleBorderColorChange = (color) => {
    setBorder({...border,
      color: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
    })

    canvas.getActiveObjects().forEach(obj => {
      obj.set('strokeStyle', `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)
      obj.set('stroke', `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)
    })
    canvas.renderAll()

    delayedAction(1200, () => {
      canvas.trigger('object:modified')
    })
  }

  const handleColorChange = (colorHex) => {
    var color = hexToRgb(colorHex.target.value)

    setBorder({...border,
      color: `rgba(${color.r}, ${color.g}, ${color.b}, ${1})`
    })

    canvas.getActiveObjects().forEach(obj => {
      obj.set('strokeStyle', `rgba(${color.r}, ${color.g}, ${color.b}, ${1})`)
      obj.set('stroke', `rgba(${color.r}, ${color.g}, ${color.b}, ${1})`)
    })
    
    canvas.renderAll()

    delayedAction(1200, () => {
      canvas.trigger('object:modified')
    })
  }

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  return (
    <>
      <div className="setting">
        <div className="label lang" data-en="Width" data-pt="Largura" data-es="Ancho">Width</div><div className="function">
          <InputAmount unit="" value={border.width}
            handleDecrease={() => handleBorderWidthChange('decrease')}
            handleChange={(e) => handleBorderWidthChange('change', e.target.value)}
            handleIncrease={() => handleBorderWidthChange('increase')}
            />
        </div>
      </div>
      <div className="setting">
          <div className="label lang" data-en="Style" data-pt="Estilo" data-es="Estilo">Style</div><div className="function">
            <InputSelect activeOption={
                {
                  strokeDashArray: border.style.strokeDashArray ?? [],
                  strokeLineCap: border.style.strokeLineCap
                }
              } handleChange={ (style) => handleBorderStyleChange(style) }>
              <Option value={ { strokeDashArray: [], strokeLineCap: 'butt' } }>
                <svg><line x1="0" y1="2" x2="100" y2="2" stroke="#000" strokeWidth="4" strokeLinecap="square" /></svg>
              </Option>
              <Option value={ { strokeDashArray: [1, 10], strokeLineCap: 'butt' } }>
                <svg><line x1="0" y1="2" x2="100" y2="2" stroke="#000" strokeWidth="4" strokeDasharray="1, 10" strokeLinecap="square" /></svg>
              </Option>
              <Option value={ { strokeDashArray: [1, 10], strokeLineCap: 'round' } }>
                <svg><line x1="0" y1="2" x2="100" y2="2" stroke="#000" strokeWidth="4" strokeDasharray="1, 10" strokeLinecap="round" /></svg>
              </Option>
              <Option value={ { strokeDashArray: [15, 15], strokeLineCap: 'square' } }>
                <svg><line x1="0" y1="2" x2="100" y2="2" stroke="#000" strokeWidth="4" strokeDasharray="15" strokeLinecap="square" /></svg>
              </Option>
              <Option value={ { strokeDashArray: [15, 15], strokeLineCap: 'round' } }>
                <svg><line x1="0" y1="2" x2="100" y2="2" stroke="#000" strokeWidth="4" strokeDasharray="15" strokeLinecap="round" /></svg>
              </Option>
              <Option value={ { strokeDashArray: [25, 25], strokeLineCap: 'square' } }>
                <svg><line x1="0" y1="2" x2="100" y2="2" stroke="#000" strokeWidth="4" strokeDasharray="25" strokeLinecap="square" /></svg>
              </Option>
              <Option value={ { strokeDashArray: [25, 25], strokeLineCap: 'round' } }>
                <svg><line x1="0" y1="2" x2="100" y2="2" stroke="#000" strokeWidth="4" strokeDasharray="25" strokeLinecap="round" /></svg>
              </Option>
              <Option value={ { strokeDashArray: [1, 8, 16, 8, 1, 20], strokeLineCap: 'square' } }>
                <svg><line x1="2" y1="2" x2="100" y2="2" stroke="#000" strokeWidth="4" strokeDasharray="1 8 16 8 1 20" strokeLinecap="square" /></svg>
              </Option>
              <Option value={ { strokeDashArray: [1, 8, 16, 8, 1, 20], strokeLineCap: 'round' } }>
                <svg><line x1="2" y1="2" x2="100" y2="2" stroke="#000" strokeWidth="4" strokeDasharray="1 8 16 8 1 20" strokeLinecap="round" /></svg>
              </Option>
            </InputSelect>
          </div>
      </div>
      <div className="setting">
        <div className="label lang" data-en="Border Type" data-pt="Tipo de borda" data-es="Tipo de borde">{__('Border Type')}</div><div className="function">
          <InputSelect activeOption={border.corner} handleChange={(corner) => handleBorderCornerChange(corner)}>
            <Option value="miter">{__('Square')}</Option>
            <Option value="round">{__('Round')}</Option>
          </InputSelect>
        </div>
      </div>
      <div className="setting">
        <div className="label lang" data-en="Color" data-pt="Cor" data-es="Color">{__('Color')}</div><div className="function">
          <input type="color" defaultValue="#1DB8CE" id="colorPickerBorder" onChange={handleColorChange}></input>
        </div>
      </div>
      {/**<div className="setting">
        <div className="label">{__('Minhas Cores')}</div><div className="function">
          <div id="my-colors-border">
            <input type="color" className="holder-color holder-color-border"></input>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default SelectionBorderSettings
