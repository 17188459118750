import React from 'react';
import './ChangeSport.scss';
import __ from './../utils/translation';
import $ from "jquery";

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles('dark', {
        backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ChangeSport = ({ canvas }) => {

    const [expanded, setExpanded] = React.useState('panel1');
    const [selectedValue, setSelectedValue] = React.useState('a');
    const [selectedBaseValue, setSelectedBaseValue] = React.useState('tradicional');
    const [selectedGolaValue, setSelectedGolaValue] = React.useState('Arsenal');
    const [selectedMangaValue, setSelectedMangaValue] = React.useState('curta');
    const [selectedPoseValue, setSelectedPoseValue] = React.useState('neutra');
    const [selectedShortValue, setSelectedShortValue] = React.useState('neutra');
    const [selectedBaseCorteValue, setSelectedBaseCorteValue] = React.useState('neutra');

    window["get_variant"] = function(){
        return [
            selectedPoseValue,
            selectedBaseValue,
            selectedGolaValue,
            selectedMangaValue,
            selectedBaseCorteValue,
            selectedShortValue,
        ]
    }

    window["set_variant"] = function(){
        setSelectedPoseValue(window.variantes[0]);
        setSelectedBaseValue(window.variantes[1]);
        setSelectedGolaValue(window.variantes[2]);
        setSelectedMangaValue(window.variantes[3]);
        setSelectedBaseCorteValue(window.variantes[4]);
        setSelectedShortValue(window.variantes[5]);
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleChangePose = (event) => {
        setSelectedPoseValue(event.target.value);

        var path, pose, load,index;

        if (event.target.value == "neutra") {

            if (!window.posesLoaded[0]) {
                load = true;
            }

            path = "./models/test.glb";
            pose = window.posesLoaded[0];
            index=0;
        } else if (event.target.value == "pose1") {

            if (!window.posesLoaded[1]) {
                load = true;
            }

            path = "./models/test2.glb";
            pose = window.posesLoaded[1];
            index=1;
        } else if (event.target.value == "pose2") {

            if (!window.posesLoaded[2]) {
                load = true;
            }

            path = "./models/test3.glb";
            pose = window.posesLoaded[2];
            index=2;
        } else if (event.target.value == "pose3") {

            if (!window.posesLoaded[3]) {
                load = true;
            }

            path = "./models/test4.glb";
            pose = window.posesLoaded[3];
            index=3;
        }

        console.log(load)
        console.log(path)
        console.log(pose)

        window["loadPose"](load, path, pose, index);
    };

    const handleChangeBase = (event) => {
        setSelectedBaseValue(event.target.value);

        var manga;

        if (selectedMangaValue == "curta") {
            manga = '';
        } else if (selectedMangaValue == "longa") {
            manga = '001';
        } else {
            manga = '002';
        }

        var base = "";

        if (event.target.value == "raglan") {
            base = "R_";
        }

        for (var i = 0; i < window.currentPose.children.length; i++) {
            window.currentPose.children[i].visible = false;
        }

        window.currentPose.getObjectByName("avatar").visible = true;
        window.currentPose.getObjectByName("short").visible = true;
        window.currentPose.getObjectByName("meia").visible = true;
        window.currentPose.getObjectByName(base + selectedGolaValue + manga).visible = true;
        window.currentUp = window.currentPose.getObjectByName(base + selectedGolaValue + manga);

        mangaState(event.target.value, selectedMangaValue);
    };

    const handleChangeGola = (event) => {
        setSelectedGolaValue(event.target.value);

        for (var i = 0; i < window.currentPose.children.length; i++) {
            window.currentPose.children[i].visible = false;
        }

        var manga;

        if (selectedMangaValue == "curta") {
            manga = '';
        } else if (selectedMangaValue == "longa") {
            manga = '001';
        } else {
            manga = '002';
        }

        var base = "";

        if (selectedBaseValue == "raglan") {
            base = "R_";
        }

        window.currentPose.getObjectByName("avatar").visible = true;
        window.currentPose.getObjectByName("short").visible = true;
        window.currentPose.getObjectByName("meia").visible = true;
        window.currentPose.getObjectByName(base + event.target.value + manga).visible = true;
        window.currentUp = window.currentPose.getObjectByName(base + event.target.value + manga);

        //mangaState(selectedBaseValue, selectedMangaValue);
    };

    const handleChangeManga = (event) => {
        setSelectedMangaValue(event.target.value);

        for (var i = 0; i < window.currentPose.children.length; i++) {
            window.currentPose.children[i].visible = false;
        }

        var manga;

        if (event.target.value == "curta") {
            manga = '';
        } else if (event.target.value == "longa") {
            manga = '001';
        } else {
            manga = '002';
        }

        var base = "";

        if (selectedBaseValue == "raglan") {
            base = "R_";
        }

        window.currentPose.getObjectByName("avatar").visible = true;
        window.currentPose.getObjectByName("short").visible = true;
        window.currentPose.getObjectByName("meia").visible = true;
        window.currentPose.getObjectByName(base + selectedGolaValue + manga).visible = true;
        window.currentUp = window.currentPose.getObjectByName(base + selectedGolaValue + manga);

        mangaState(selectedBaseValue, event.target.value);
    };

    function mangaState(base, manga) {
        for (let i = 0; i < window.originalLayers.length; i++) {

            if (manga != "curta" || base == "raglan") {
                if (window.originalLayers[i].id == "MLD" || window.originalLayers[i].id == "MLE") {
                    window.originalLayers[i].visible = true;
                } else if (window.originalLayers[i].id == "MD" || window.originalLayers[i].id == "ME") {
                    window.originalLayers[i].visible = false;
                }
            } else {
                if (window.originalLayers[i].id == "MLD" || window.originalLayers[i].id == "MLE") {
                    window.originalLayers[i].visible = false;
                } else if (window.originalLayers[i].id == "MD" || window.originalLayers[i].id == "ME") {
                    window.originalLayers[i].visible = true;
                }
            }
        }

        update();
    }

    window["setupPose"] = function () {

        console.log(selectedBaseValue)
        console.log(selectedMangaValue)
        console.log(selectedGolaValue)
        console.log(selectedShortValue)

        var base = "";

        if (selectedBaseValue == "raglan") {
            base = "R_";
        }

        var manga;

        if (selectedMangaValue == "curta") {
            manga = '';
        } else if (selectedMangaValue == "longa") {
            manga = '001';
        } else {
            manga = '002';
        }

        for (var i = 0; i < window.currentPose.children.length; i++) {
            window.currentPose.children[i].visible = false;
        }

        window.currentPose.getObjectByName("avatar").visible = true;
        window.currentPose.getObjectByName("short").visible = true;
        window.currentPose.getObjectByName("meia").visible = true;
        window.currentPose.getObjectByName(base + selectedGolaValue + manga).visible = true;

        window.currentUp = window.currentPose.getObjectByName(base + selectedGolaValue + manga);

        if (selectedShortValue == "barra") {
            window.currentPose.getObjectByName("short").children[1].material.alphaMap = window.alphaShort;
            window.currentPose.getObjectByName("short").children[0].material.alphaMap = window.alphaShort;
        }

        update();
    }

    const handleChangeShort = (event) => {
        setSelectedShortValue(event.target.value);

        for (let i = 0; i < window.originalLayers.length; i++) {
            if (window.originalLayers[i].id == "corte_traseiro" || window.originalLayers[i].id == "corte_lateral_e" ||
                window.originalLayers[i].id == "corte_lateral_d"
            ) {
                window.originalLayers[i].visible = false;
            }
        }

        window.currentPose.getObjectByName("short").children[1].material.alphaMap = null;
        window.currentPose.getObjectByName("short").children[0].material.alphaMap = null;

        if (event.target.value == "neutra") {
            update();
            return;
        } else if (event.target.value == "barra") {
            //
            window.currentPose.getObjectByName("short").children[1].material.alphaMap = window.alphaShort;
            window.currentPose.getObjectByName("short").children[0].material.alphaMap = window.alphaShort;
            update();
            return;
        }

        for (let i = 0; i < window.originalLayers.length; i++) {
            if (event.target.value == "lateral") {
                if (window.originalLayers[i].id == "corte_lateral_e" || window.originalLayers[i].id == "corte_lateral_d") {
                    window.originalLayers[i].visible = true;
                }
            } else if (event.target.value == "traseiro") {
                if (window.originalLayers[i].id == "corte_traseiro") {
                    window.originalLayers[i].visible = true;
                }
            }
        }

        update();
    };

    const handleChangeBaseCorte = (event) => {
        setSelectedBaseCorteValue(event.target.value);

        for (let i = 0; i < window.originalLayers.length; i++) {
            if (window.originalLayers[i].id == "base_corte1" || window.originalLayers[i].id == "base_corte2") {
                window.originalLayers[i].visible = false;
            }
        }

        if (event.target.value == "neutra") {
            update();
            return;
        }

        for (let i = 0; i < window.originalLayers.length; i++) {
            if (window.originalLayers[i].id == "base_corte1" || window.originalLayers[i].id == "base_corte2") {
                window.originalLayers[i].visible = true;
            }
        }

        update();
    };

    function update() {
        setTimeout(() => {
            window.fabricCanvas.requestRenderAll();
            setTimeout(() => {
                window["updateCanvas"]();
            }, 100);
        }, 100);
    }

    return (
        <>
            <p className="title lang" data-en="CHANGE CLOTH" data-es="CAMBIARSE DE ROPA" data-pt="MUDAR ROUPA">{__('CHANGE CLOTH')}</p>

            <div>
                <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
                    <AccordionSummary aria-controls="panel0d-content" id="panel0d-header">
                        <Typography>Pose</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='list'>
                            <div>
                                <Radio
                                    checked={selectedPoseValue === 'neutra'}
                                    onChange={handleChangePose}
                                    value="neutra"
                                    name="radio-buttons"
                                />
                                <label>Neutra</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedPoseValue === 'pose1'}
                                    onChange={handleChangePose}
                                    value="pose1"
                                    name="radio-buttons"
                                />
                                <label>Pose 1</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedPoseValue === 'pose2'}
                                    onChange={handleChangePose}
                                    value="pose2"
                                    name="radio-buttons"
                                />
                                <label>Pose 2</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedPoseValue === 'pose3'}
                                    onChange={handleChangePose}
                                    value="pose3"
                                    name="radio-buttons"
                                />
                                <label>Pose 3</label>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>Base</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Radio
                                checked={selectedBaseValue === 'tradicional'}
                                onChange={handleChangeBase}
                                value="tradicional"
                                name="radio-buttons"
                            />
                            <label>Tradicional</label>
                            <Radio
                                checked={selectedBaseValue === 'raglan'}
                                onChange={handleChangeBase}
                                value="raglan"
                                name="radio-buttons"
                            />
                            <label>Raglan</label>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Gola</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='list'>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Arsenal'}
                                    onChange={handleChangeGola}
                                    value="Arsenal"
                                    name="radio-buttons"
                                />
                                <label>Arsenal</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Atletica'}
                                    onChange={handleChangeGola}
                                    value="Atletica"
                                    name="radio-buttons"
                                />
                                <label>Atlética</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Avante'}
                                    onChange={handleChangeGola}
                                    value="Avante"
                                    name="radio-buttons"
                                />
                                <label>Avante</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'CBF'}
                                    onChange={handleChangeGola}
                                    value="CBF"
                                    name="radio-buttons"
                                />
                                <label>CBF</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'EVO'}
                                    onChange={handleChangeGola}
                                    value="EVO"
                                    name="radio-buttons"
                                />
                                <label>EVO</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Gola_alta_c_ziper'}
                                    onChange={handleChangeGola}
                                    value="Gola_alta_c_ziper"
                                    name="radio-buttons"
                                />
                                <label>Alta com Ziper</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Gola_redonda'}
                                    onChange={handleChangeGola}
                                    value="Gola_redonda"
                                    name="radio-buttons"
                                />
                                <label>Redonda</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'JEC'}
                                    onChange={handleChangeGola}
                                    value="JEC"
                                    name="radio-buttons"
                                />
                                <label>JEC</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Padre'}
                                    onChange={handleChangeGola}
                                    value="Padre"
                                    name="radio-buttons"
                                />
                                <label>Padre</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Penalty'}
                                    onChange={handleChangeGola}
                                    value="Penalty"
                                    name="radio-buttons"
                                />
                                <label>Penalty</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Polo'}
                                    onChange={handleChangeGola}
                                    value="Polo"
                                    name="radio-buttons"
                                />
                                <label>Polo</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Polo_Corinthians'}
                                    onChange={handleChangeGola}
                                    value="Polo_Corinthians"
                                    name="radio-buttons"
                                />
                                <label>Polo Corinthians</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Polo_v'}
                                    onChange={handleChangeGola}
                                    value="Polo_v"
                                    name="radio-buttons"
                                />
                                <label>Polo V</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Polo_v_especial'}
                                    onChange={handleChangeGola}
                                    value="Polo_v_especial"
                                    name="radio-buttons"
                                />
                                <label>Polo V Especial</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Red_Horse'}
                                    onChange={handleChangeGola}
                                    value="Red_Horse"
                                    name="radio-buttons"
                                />
                                <label>Red Horse</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Santos'}
                                    onChange={handleChangeGola}
                                    value="Santos"
                                    name="radio-buttons"
                                />
                                <label>Santos</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'selecao'}
                                    onChange={handleChangeGola}
                                    value="selecao"
                                    name="radio-buttons"
                                />
                                <label>Seleção</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'selecao_ribana_c_ziper'}
                                    onChange={handleChangeGola}
                                    value="selecao_ribana_c_ziper"
                                    name="radio-buttons"
                                />
                                <label>Seleção Ribana com Ziper</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'transpassada'}
                                    onChange={handleChangeGola}
                                    value="transpassada"
                                    name="radio-buttons"
                                />
                                <label>Transpassada</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'v_umbro'}
                                    onChange={handleChangeGola}
                                    value="v_umbro"
                                    name="radio-buttons"
                                />
                                <label>V Umbro</label>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography>Manga</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Radio
                                checked={selectedMangaValue === 'curta'}
                                onChange={handleChangeManga}
                                value="curta"
                                name="radio-buttons"
                            />
                            <label>Curta</label>
                            <Radio
                                checked={selectedMangaValue === '3/4'}
                                onChange={handleChangeManga}
                                value="3/4"
                                name="radio-buttons"
                            />
                            <label>3/4</label>
                            <Radio
                                checked={selectedMangaValue === 'longa'}
                                onChange={handleChangeManga}
                                value="longa"
                                name="radio-buttons"
                            />
                            <label>Longa</label>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                        <Typography>Base Cortes</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Radio
                                checked={selectedBaseCorteValue === 'neutra'}
                                onChange={handleChangeBaseCorte}
                                value="neutra"
                                name="radio-buttons"
                            />
                            <label>Base sem corte lateral</label>
                            <Radio
                                checked={selectedBaseCorteValue === 'corte'}
                                onChange={handleChangeBaseCorte}
                                value="corte"
                                name="radio-buttons"
                            />
                            <label>Base corte lateral</label>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                        <Typography>Short</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='list'>
                            <div>
                                <Radio
                                    checked={selectedShortValue === 'neutra'}
                                    onChange={handleChangeShort}
                                    value="neutra"
                                    name="radio-buttons"
                                />
                                <label>2 partes inteiro</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedShortValue === 'lateral'}
                                    onChange={handleChangeShort}
                                    value="lateral"
                                    name="radio-buttons"
                                />
                                <label>Corte lateral</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedShortValue === 'barra'}
                                    onChange={handleChangeShort}
                                    value="barra"
                                    name="radio-buttons"
                                />
                                <label>Corte Barra</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedShortValue === 'traseiro'}
                                    onChange={handleChangeShort}
                                    value="traseiro"
                                    name="radio-buttons"
                                />
                                <label>Corte trazeiro</label>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export default ChangeSport