import React, { useState, useEffect } from 'react';
import { fabric } from 'fabric';

import delayedAction from './../utils/delayedAction';
import { stateObjToGrad } from './../utils/gradientTools';
import __ from './../utils/translation';

import $ from "jquery";
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker';

const SelectionColorSettings = ({ canvas, activeSelection }) => {

  //const [color, setColor] = useState('rgba(255, 255, 0, 1)')
  const [color, setColor] = useState('rgba(210,211,213,1)');
  const { getGradientObject } = useColorPicker(color, setColor);
  const gradientObject = getGradientObject();

  const [gradient] = useState({
    type: 'linear',
    angle: 90,
    colorStops: [
      { offset: 0, color: 'rgba(0, 0, 0, 1)', id: 0 },
      { offset: 1, color: 'rgba(255, 0, 0, 1)', id: 1 }
    ],
    reset: true
  })

  // load selected object's color settings on panel load
  useEffect(() => {
    if (!activeSelection.fill) return
    // obj has color
    if (!activeSelection.fill.colorStops) {
      let color = new fabric.Color(activeSelection.fill);
      let colorRgba = color.getSource()
      //setColor(`rgba(${colorRgba[0]}, ${colorRgba[1]}, ${colorRgba[2]}, ${colorRgba[3]})`)
      $("#colorPicker").val(rgbToHex(color._source[0], color._source[1], color._source[2]));
    }

    console.log(activeSelection)

    if(activeSelection.colorMain)
      setColor(activeSelection.colorMain);
    else{
      if(activeSelection.fill.colorStops){

      }else{
        setColor(activeSelection.fill);
      }
    }
      

    if(!document.getElementById("list-colors"))
      return;

    var cloned = document.getElementById("list-colors").cloneNode(true);

    if($("#my-colors-svg").children().length == 0)
      document.getElementById("my-colors-svg").appendChild(cloned);
    /*$("#my-colors-svg #list-colors").css("position", "absolute");
    $("#my-colors-svg #list-colors").css("z-index", "100");
    $("#my-colors-svg #list-colors").css("margin-top", "-10px");
    $("#my-colors-svg #list-colors").css("display", "none");
    $("#my-colors-svg #list-colors div").css("border", "none");*/
  }, [activeSelection])


  $("body").on('click', '#my-colors-svg #list-colors div', function (evt) {
    if(activeSelection._objects){
      for(var i =0; i<activeSelection._objects.length; i++){
        activeSelection._objects[i].set('fill', `rgba(${$(this).data("r")}, ${$(this).data("g")}, ${$(this).data("b")}, ${1})`)
      }
    }else{
      activeSelection.set('fill', `rgba(${$(this).data("r")}, ${$(this).data("g")}, ${$(this).data("b")}, ${1})`)
    }

    $("#colorPicker").val(rgbToHex($(this).data("r"), $(this).data("g"), $(this).data("b")));
    canvas.renderAll();
    
    //setTimeout(function () {
      canvas.trigger('object:modified')
      $(".holder-color-svg").val($("#colorPicker").val());
    //}, 1000);

    if(window.is_iframe)
      window['updateCanvas']();
  });

  const handleColorChange = (color) => {
    //
    setColor(color);

    activeSelection.colorMain = color;

    if(color.includes("gradient")){
      if(gradientObject.isGradient){
        gradient.type = gradientObject.gradientType.replace("-gradient", "");
        gradient.angle = parseInt(gradientObject.degrees.replace("deg", ""));
        gradient.colorStops = [];
  
        for(var i=0; i<gradientObject.colors.length; i++){
  
          gradient.colorStops.push({
            color: gradientObject.colors[i].value, 
            offset: gradientObject.colors[i].left / 100, 
            id: i});
        }
  
        let fabricGrad = stateObjToGrad(gradient, activeSelection.width, activeSelection.height, fabric)
  
        if(activeSelection._objects){
          for(var i =0; i<activeSelection._objects.length; i++){
            activeSelection._objects[i].set('fill', fabricGrad)
          }
        }else{
          activeSelection.set('fill', fabricGrad)
        }
      }
    }else{
      if(activeSelection._objects){
        for(var i =0; i<activeSelection._objects.length; i++){
          activeSelection._objects[i].set('fill', color)
        }
      }else{
        activeSelection.set('fill', color)
      }
    }
    
    canvas.renderAll()

    delayedAction(1200, () => {
      canvas.trigger('object:modified')
    })

    if(window.is_iframe)
      window['updateCanvas']();
  }

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  $(".color-type").bind("click", function () {
    $(".color-type").css("text-decoration", "none");
    $(this).css("text-decoration", "underline");

    if($(this).data("type") == "color"){
      $("#panel-my-colors").css("display", "none");
      $("#panel-color").css("display", "flex");
    }else{
      $("#panel-my-colors").css("display", "flex");
      $("#panel-color").css("display", "none");
    }
  });

  return (
    <div>
      <div className='options options-colors'>
        <p id="option-color" data-type="color" className="color-type lang" data-en="Colors" data-pt="Cores" data-es="Colores">{__('Colors')}</p>
        <p id="option-my-colors" data-type="myColor" className="color-type lang" data-en="My Colors" data-pt="Minhas Cores" data-es="Mis Colores">{__('My Colors')}</p>
      </div>
      <div id="panel-color" className="setting setting-color">
        <div className="function">
          {/**<input type="color" defaultValue="#1DB8CE" id="colorPicker" onChange={handleColorChange}></input> */}
          <ColorPicker value={color} onChange={handleColorChange} />
        </div>
      </div>
      <div id="panel-my-colors" className="setting setting-color">
        <div className="function">
          <div id="my-colors-svg">
            {/**<input type="color" className="holder-color holder-color-svg"></input> */}
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default SelectionColorSettings
