import React, { useState, useEffect } from 'react';
import './Annotation.scss';
import __ from './../utils/translation';
import $ from "jquery";
import { downloadImage, downloadSVG } from '../utils/downloadImage';
import { applyZoom, zoomWithKeys, zoomWithMouse, zoomOptions } from '../utils/zoom';
import { ReactComponent as IconDownload2 } from '../icons/file_download.svg';

const Clone = ({ canvas }) => {

    

    return (
        <>
            <p className="title">{__('CLONE')}</p>

            <span className='warning-sidebar lang' data-en="Clone does not work if animations are on" data-pt="Clone não funciona se as animações estiverem ativadas" data-es="La clonación no funciona si las animaciones están activadas.">*Clone does not work if animations are on {/*<br></br> *If you make changes to the cloth or avatar, update by clicking on the state button*/}</span>

            <div id="option-clone">
                <button id="state-clone" className='lang' data-en="State of Clone" data-pt="Estado do Clone" data-es="Estado de clonación">State of Clone</button>
                <button id="state-trans" className='lang' data-en="State of Arrows" data-pt="Estado das Flechas" data-es="Estado de flechas">State of Arrows</button>
                <button id="state-pos" className='lang' data-en="Movement Mode" data-pt="Modo de movimento" data-es="Modo de movimiento">Movement Mode</button>
                <button id="state-rot" className='lang' data-en="Rotation Mode" data-pt="Modo de rotação" data-es="Modo de rotación">Rotation Mode</button>
            </div>
            
        </>
    )
}

export default Clone