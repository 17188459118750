import React, { useState, useEffect } from 'react';
import './Video.scss';
import { fabric } from 'fabric';
import { gradToStateObj, stateObjToGrad } from './../utils/gradientTools';
import { Tabs, Tab } from './Tabs';
import { ChromePicker } from 'react-color';
import GradientPicker from './GradientPicker';
import __ from './../utils/translation';
import $ from "jquery";
import InputAmount from './InputAmount';

import { ReactComponent as IconTrash } from '../icons/trash.svg';
import { ReactComponent as IconPlay } from '../icons/play.svg';
import { ReactComponent as IconAdd } from '../icons/add.svg';

const Video = ({ canvas }) => {

    const [drawSettings, setDrawSettings] = useState({
        duration: 1,
        time: 10,
        medium: true,
        low: false,
        high: false
    })

    /*if(localStorage.getItem("video_time=obj-" + window.idHolder) && window.video_timeOptions){
        window.video_timeOptions = false;
        drawSettings.time = parseFloat(localStorage.getItem("video_time=obj-" + window.idHolder));
    }

    if(localStorage.getItem("video_speed=obj-" + window.idHolder) && window.video_speedOptions){
        window.video_speedOptions = false;
        drawSettings.duration = parseFloat(localStorage.getItem("video_speed=obj-" + window.idHolder));
    }*/

    window['video_time'] = function(val){
        console.log(val)
        drawSettings.time=parseFloat(val);
    }

    window['video_speed'] = function(val){
        drawSettings.duration=parseFloat(val);
    }

    const handleVideoDuration = (action, amount) => {
        let duration = 1

        if (action === 'decrease') {
            duration = drawSettings.time
            duration = duration === 1 ? 1 : duration - 1
        }

        if (action === 'increase') {
            duration = drawSettings.time + 1
        }

        if (action === 'change') {
            duration = parseInt(amount)
        if (!Number.isInteger(duration)) return
        }

        //window.video_duration = duration;
        //window['setRotateSpeed'](duration);

        setDrawSettings({...drawSettings,
            time: duration
        })
    }

    const handleVideoTime = (action, amount) => {
        let duration = 1

        if (action === 'decrease') {
            duration = drawSettings.duration
            duration = duration === 1 ? 1 : duration - 1
        }

        if (action === 'increase') {
            duration = drawSettings.duration + 1
        }

        if (action === 'change') {
            duration = parseInt(amount)
        if (!Number.isInteger(duration)) return
        }

        //window.video_duration = duration;
        //window['setRotateSpeed'](duration);

        setDrawSettings({...drawSettings,
            duration: duration
        })
    }

    window.type_video = "auto";

    $("body").on('click', '.video-type', function () {
        $(".video-type").css("text-decoration", "none");
        $(this).css("text-decoration", "underline");

        $(".video").css("display", "none");
        $("#video-panel-" + $(this).data("type")).css("display", "block");

        window.type_video = $(this).data("type");

        if(window.type_video == "create"){
            window.controls.autoRotate = false;
        }

        //window.id_sport = $(this).attr("id");
        //window.gender_sport = $(this).data("gender");
    });

    return (
        <>
            <p className="title lang" data-en="PRESENTATION" data-pt="APRESENTAÇÃO" data-es="PRESENTACIÓN">{__('PRESENTATION')}</p>

            {/**<p>{__(`O desempenho dependerá da potência do computador`)}</p> */}

            <p className='lang' data-en="The video will be downloaded in webm format, if you want to convert it to mp4, visit this website:" data-pt="O vídeo será baixado no formato webm, caso queira convertê-lo para mp4 visite este site:" data-es="El vídeo se descargará en formato webm, si deseas convertirlo a mp4 visita este sitio web:">{__(`The video will be downloaded in webm format, if you want to convert it to mp4, visit this website:`)}</p>

            <a target='_blank' className='link' href='https://convertio.co/pt/webm-mp4/'>link</a>

            <div className='options'>
                <p id="video-auto" data-type="auto" className="subtitle video-type lang" data-en="Rotate" data-pt="Rotacionar" data-es="Girar">{__('Rotate')}</p>
                <p id="video-edit" data-type="create" className="subtitle video-type lang" data-en="Path" data-pt="Pontos" data-es="Camino">{__('Path')}</p>
            </div>

            <div className='options'>
                <button id="btn-start-camera" className='lang' data-en="View" data-pt="Visualizar" data-es="Vista">View</button>
                <button id="btn-save-video-options" className='lang' data-en="Save" data-pt="Salvar" data-es="Salvar">Save</button>
            </div>

            <div id="video-resolution" className='video-hide'>
                <div className="input-select">
                    <div className="select" id='select-map'>
                        <div className="active-option" id="active-option-res">1920x1080</div>
                        <div className="list" id='list-maps'>
                            <div className='video-res-separator lang' data-en="Landscape" data-pt="Paisagem" data-es="Paisaje">Landscape</div>
                            <div className="option option-res">900x600</div>
                            <div className="option option-res">1280x720</div>
                            <div className="option option-res">1920x1080</div> 
                            <div className="option option-res">2560x1440</div>
                            <div className='video-res-separator lang' data-en="Portrait" data-pt="Retrato" data-es="Retrato">Portrait</div>
                            <div className="option option-res">600x900</div>
                            <div className="option option-res">720x1280</div>
                            <div className="option option-res">1080x1920</div>
                            <div className="option option-res">1440x2560</div>
                            <div className='video-res-separator lang' data-en="Square" data-pt="Quadrado" data-es="Cuadrado">Square</div>
                            <div className="option option-res">600x600</div>
                            <div className="option option-res">720x720</div>
                            <div className="option option-res">900x900</div>
                            <div className="option option-res">1080x1080</div>
                            <div className="option option-res">1280x1280</div>
                            <div className="option option-res">1440x1440</div>
                            <div className="option option-res">1920x1920</div>
                            <div className="option option-res">2560x2560</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="video-options video-hide">
                <div className="input-select">
                    <div className="select" id='select-fps'>
                        <div className="active-option" id="active-option-fps">30fps</div>
                        <div className="list" id='list-fps'>
                            <div className="option option-fps" data-fps="20">20fps</div>
                            <div className="option option-fps" data-fps="30">30fps</div>
                            <div className="option option-fps" data-fps="60">60fps</div>
                            <div className="option option-fps" data-fps="120">120fps</div>
                        </div>
                    </div>
                </div>
            </div> 

            <div className="video-options">
                <button id="btn-generate-video" className='canvas_capture_toggle lang' data-index="7" data-canvas-is-local="true"
                data-frame-u-u-i-d="top" data-canvas-index="7" data-en="Generate Video" data-pt="Gerar vídeo" data-es="Generar vídeo">Generate Video</button>
            </div>

            {/**<div className="video-options">
                <button id="btn-generate-video" className='canvas_capture_toggle' data-index="2" data-canvas-is-local="true"
                data-frame-u-u-i-d="top" data-canvas-index="2">Generate Video</button>
            </div> */}

            <div id="video-panel-auto" className='video'>
                {/**<div className="rotate">
                    <button id="btn-rotate-avatar">Rotacionar</button>
                </div> */}
                <div className="setting">
                    <div className="label lang"  data-en="Camera Speed" data-pt="Velocidade da câmera" data-es="Velocidad de la cámara">{__('Camera Speed')}</div>
                    <div className="function" id="video-speed-rotate">
                        <InputAmount unit="" value={drawSettings.duration}
                        handleDecrease={() => handleVideoTime('decrease')}
                        handleChange={(e) => handleVideoTime('change', e.target.value)}
                        handleIncrease={() => handleVideoTime('increase')}
                        />
                    </div>
                </div>
                <div className="setting">
                    <div className="label lang" data-en="Time (seconds)" data-pt="Tempo (segundos)" data-es="Tiempo (segundos)">{__('Time (seconds)')}</div>
                    <div className="function" id="video-time">
                        <InputAmount unit="" value={drawSettings.time}
                        handleDecrease={() => handleVideoDuration('decrease')}
                        handleChange={(e) => handleVideoDuration('change', e.target.value)}
                        handleIncrease={() => handleVideoDuration('increase')}
                        />
                    </div>
                </div>
            </div>

            <div id="video-panel-create" className='video'>
                <div className='options'>
                    <button id="add-video-point">
                        <IconAdd />
                    </button>
                    {/**<button id="btn-start-camera">
                        <IconPlay />
                    </button> */}
                    <button id="btn-delete-camera-points">
                        <IconTrash />
                    </button>
                </div>
                <div id="video-points"></div>
            </div>
        </>
    )
}

export default Video