import React, { useState, useEffect } from 'react';
import './Annotation.scss';
import __ from './../utils/translation';
import $ from "jquery";
import { downloadImage, downloadSVG } from '../utils/downloadImage';
import { applyZoom, zoomWithKeys, zoomWithMouse, zoomOptions } from '../utils/zoom';
import { ReactComponent as IconDownload2 } from '../icons/file_download.svg';

const TextState = ({ canvas }) => {

    return (
        <>
            <p className="title">{__('3D TEXT')}</p>

            <div id="options-general">
                <div className='option-general'>
                    <span>Visibility:</span>
                    <div className='value-general'>
                        <input type='checkbox'></input>
                    </div>
                </div>
                <div className='option-general'>
                    <span>Fill:</span>
                    <div className='value-general'>
                        <input type='checkbox'></input>
                    </div>
                </div>
                <div className='option-general'>
                    <span>Opacity:</span>
                    <div className='value-general'>
                        <input type='range'></input>
                    </div>
                </div>
                <div className='option-general'>
                    <span>Scale:</span>
                    <div className='value-general'>
                        <input type='range'></input>
                    </div>
                </div>
                <div className='option-general'>
                    <span>Color:</span>
                    <div className='value-general'>
                        <input type='color'></input>
                    </div>
                </div>
                {/**<div className='option-general'>
                    <span>Fonte:</span>
                    <div className='value-general'>
                        <div className="input-select">
                            <div className="select" id='select-fps'>
                                <div className="active-option" id="active-option-fps">30fps</div>
                                <div className="list" id='list-fps'>
                                    <div className="option option-fps" data-fps="20">20fps</div>
                                    <div className="option option-fps" data-fps="30">30fps</div>
                                    <div className="option option-fps" data-fps="60">60fps</div>
                                    <div className="option option-fps" data-fps="120">120fps</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            
        </>
    )
}

export default TextState