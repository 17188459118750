import React, { useState, useEffect } from 'react';
import './Download.scss';
import __ from './../utils/translation';
import $ from "jquery";
import { downloadImage, downloadSVG } from '../utils/downloadImage';
import { applyZoom, zoomWithKeys, zoomWithMouse, zoomOptions } from '../utils/zoom';
import { ReactComponent as IconDownload2 } from '../icons/file_download.svg';

const Download = ({ canvas }) => {

    const [downloadMenuVisible, setDownloadMenuVisible] = useState(false)
    const [zoom, setZoom] = useState(1)


    $("#download-svg").on("click", function () {
        setZoom(1);
        applyZoom(canvas, 1);
        setDownloadMenuVisible(false);
        downloadSVG(canvas.toSVG(['default', 'id', 'scaleX', 'scaleY', 'type']));
    });

    window["downloadSVG"] = function(){
        setZoom(1);
        applyZoom(canvas, 1);
        setDownloadMenuVisible(false);
        downloadSVG(canvas.toSVG(['default', 'id', 'scaleX', 'scaleY', 'type']));
    }

    $("#download-png").on("click", function () {
        setZoom(1);
        applyZoom(canvas, 1);
        setDownloadMenuVisible(false);
        downloadImage(canvas.toDataURL(), 'png', 'image/png');
    });

    $("#download-jpg").on("click", function () {
        setZoom(1);
        applyZoom(canvas, 1);
        setDownloadMenuVisible(false);
        //downloadImage(canvas.toDataURL(), 'jpg', 'image/jpg');
        downloadImage(canvas.toDataURL(), 'png', 'image/png');
    });

    window["downloadJPG"] = function(){
        setZoom(1);
        applyZoom(canvas, 1);
        setDownloadMenuVisible(false);
        //downloadImage(canvas.toDataURL(), 'jpg', 'image/jpg');
        downloadImage(canvas.toDataURL(), 'png', 'image/png');
    }

    return (
        <>
            <p className="title">{__('DOWNLOAD')}</p>
            <span className='lang'  data-en="If you want to use the vector in CorelDRAW, we recommend that you download the vector as PDF and import to avoid conflicts" data-pt="Caso queira utilizar o vetor no CorelDRAW, recomendamos que você baixe o vetor em PDF e importe para evitar conflitos" data-es="Si desea utilizar el vector en CorelDRAW, le recomendamos descargar el vector como PDF e importarlo para evitar conflictos.">If you want to use the vector in CorelDRAW, we recommend that you download the vector as PDF and import to avoid conflicts</span>
            
            <div className="downloads">
                <div id="download-all" data-title="download-all">
                    <IconDownload2 />
                    <p className='lang' data-en="Automate" data-pt="Automatizar" data-es="Automatizar">Automate</p>
                </div>
                <div id="download-pdf" data-title="resumo-pdf">
                    <IconDownload2 />
                    <p className='lang' data-en="PDF Resume" data-pt="Resumo PDF" data-es="Currículum en PDF">PDF Resume</p>
                </div>
                <div id="download-svg" data-title="vetor-svg">
                    <IconDownload2 />
                    <p className='lang'  data-en="Vector SVG" data-pt="Vetor SVG" data-es="Vector SVG">Vector SVG</p>
                </div>
                <div id="download-svg-pdf" data-title="vetor-pdf">
                    <IconDownload2 />
                    <p className='lang' data-en="Vector PDF" data-pt="Vetor PDF" data-es="Vector PDF">Vector PDF</p>
                </div>
                <div id="download-jpg" data-title="vetor-jpg">
                    <IconDownload2 />
                    <p className='lang' data-en="Vector JPG" data-pt="Vetor JPG" data-es="Vector JPG">Vector JPG</p>
                </div>
                <div className="download-jpg-3d" data-width="3600" data-height="1800" data-title="captura-paisagem">
                    <IconDownload2 />
                    <p className='lang' data-en="Landscape" data-pt="Paisagem" data-es="Paisaje">Landscape</p>
                </div>
                <div className="download-jpg-3d" data-width="1800" data-height="3600" data-title="captura-retrato">
                    <IconDownload2 />
                    <p className='lang' data-en="Portrait" data-pt="Retrato" data-es="Retrato">Portrait</p>
                </div>
                <div className="download-jpg-3d" data-width="3800" data-height="3800" data-title="captura-quadrado">
                    <IconDownload2 />
                    <p className='lang' data-en="Square" data-pt="Quadrado" data-es="Cuadrado">Square</p>
                </div>

                {/*<div className="download-jpg-3d" data-tra="true" data-width="3600" data-height="1800" data-title="captura-paisagem">
                    <IconDownload2 />
                    <p className='lang' data-en="Transparent Landscape" data-pt="Paisagem Transparente" data-es="Paisaje Transparente">Transparent Landscape</p>
                </div>
                <div className="download-jpg-3d" data-tra="true" data-width="1800" data-height="3600" data-title="captura-retrato">
                    <IconDownload2 />
                    <p className='lang' data-en="Transparent Portrait" data-pt="Retrato Transparente" data-es="Retrato Transparente">Transparent Portrait</p>
                </div>
                <div className="download-jpg-3d" data-tra="true" data-width="3800" data-height="3800" data-title="captura-quadrado">
                    <IconDownload2 />
                    <p className='lang' data-en="Transparent Square" data-pt="Quadrado Transparente" data-es="Cuadrado Transparente">Transparent Square</p>
                </div>*/}

                <div className="download-jpg-3d" data-tra="false" data-width="1500" data-height="3000" data-title="back">
                    <IconDownload2 />
                    <p className='lang' data-en="Back" data-pt="Costas" data-es="Atrás">Back</p>
                </div>

                {/**<div className="download-jpg-3d" data-tra="true" data-width="1500" data-height="3000" data-title="back">
                    <IconDownload2 />
                    <p className='lang' data-en="Back Transparent" data-pt="Costas Transparente" data-es="Atrás Transparente">Back Transparent</p>
                </div> */}

                <div id="download-vistas" data-title="frente-e-costas" data-tra="false">
                    <IconDownload2 />
                    <p className='lang' data-en="Front and Back" data-pt="Frente e Costas" data-es="Delantero y trasero">Front and Back</p>
                </div>
                {/**<div id="download-vistas" data-title="frente-e-costas" data-tra="true">
                    <IconDownload2 />
                    <p className='lang' data-en="Front and Back Transparent" data-pt="Frente e Costas Transparente" data-es="Delantero y Trasero Transparente">Front and Back Transparent</p>
                </div> */}

                <div id="download-vistas" data-title="lados" data-tra="false">
                    <IconDownload2 />
                    <p className='lang' data-en="Sides" data-pt="Lados" data-es="Lados">Sides</p>
                </div>

                <div id="download-poses" data-title="landscape-poses" data-tra="false" data-width="3600" data-height="1800">
                    <IconDownload2 />
                    <p className='lang' data-en="Poses" data-pt="Poses" data-es="Poses">Poses</p>
                </div>
                {/**<div id="download-vistas" data-title="lados" data-tra="true">
                    <IconDownload2 />
                    <p className='lang' data-en="Sides Transparent" data-pt="Lados Transparente" data-es="Lados Transparente">Sides Transparent</p>
                </div> */}
            </div>
        </>
    )
}

export default Download