import React, { useState, useEffect } from 'react';
import './Annotation.scss';
import __ from './../utils/translation';
import $ from "jquery";
import { downloadImage, downloadSVG } from '../utils/downloadImage';
import { applyZoom, zoomWithKeys, zoomWithMouse, zoomOptions } from '../utils/zoom';
import { ReactComponent as IconDownload2 } from '../icons/file_download.svg';

const Annotation = ({ canvas }) => {

    

    return (
        <>
            <p className="title lang" data-en="ANNOTATION" data-pt="ANOTAÇÕES" data-es="ANOTACIONES">{__('ANNOTATION')}</p>

            <span className='warning-sidebar lang' data-en="Annotations does not work if animations are on" data-pt="As anotações não funcionam se as animações estiverem ativadas" data-es="Las anotaciones no funcionan si las animaciones están activadas.">Annotations does not work if animations are on</span>

            <div id="option-text">
                <span className='lang' data-en="To add a note, click the plus button" data-pt="Para adicionar uma nota, clique no botão de adição" data-es="Para agregar una nota, haga clic en el botón más">*To add a note, click the plus button</span>
                <span className='lang' data-en="Camera icon: save current view" data-pt="Ícone da câmera: salva a visualização atual" data-es="Icono de cámara: guardar la vista actual">*Camera icon: save current view</span>
                <span className='lang' data-en="Trash icon: Delete the current note" data-pt="Ícone de lixeira: Exclua a nota atual" data-es="Icono de papelera: elimina la nota actual">*Trash icon: Delete the current note</span>
                <span className='lang' data-en="Arrow icon: expand annotation" data-pt="Ícone de seta: expandir anotação" data-es="Icono de flecha: ampliar anotación">*Arrow icon: expand annotation</span>
                <span className='lang' data-en="Pencil icon: Edit annotation point" data-pt="Ícone de lápis: Editar ponto de anotação" data-es="Icono de lápiz: Editar punto de anotación">*Pencil icon: Edit annotation point</span>
            </div>

            <div id="option-annotation">
                <i className="fas fa-plus-circle" id="add-annotation"></i>
            </div>
            
            <div id="annotation">
                
            </div>
        </>
    )
}

export default Annotation