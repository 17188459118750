import React, { useState, useEffect } from 'react';
import './Transitions.scss';
import __ from './../utils/translation';
import $ from "jquery";

const Transitions = ({ canvas }) => {


    return (
        <>
            <p className="title">{__('Transitions')}</p>
            
            <div className="transitions"></div>
        </>
    )
}

export default Transitions