import React, { useState, useEffect } from 'react';
import { ChromePicker } from 'react-color';
import delayedAction from './../utils/delayedAction';
import Button from './Button';
import InputAmount from './InputAmount';

import __ from './../utils/translation';

import { ReactComponent as IconTextBold } from './../icons/text-bold.svg';
import { ReactComponent as IconTextItalic } from './../icons/text-italic.svg';
import { ReactComponent as IconTextUnderline } from './../icons/text-underline.svg';
import { ReactComponent as IconTextLineThrough } from './../icons/text-linethrough.svg';
import { ReactComponent as IconTextSub } from './../icons/text-sub.svg';
import { ReactComponent as IconTextSup } from './../icons/text-sup.svg';
import $ from "jquery";
import { fabric } from 'fabric';


const SelectionTextSettings = ({ canvas, activeSelection }) => {

  const [fontFamily, setFontFamily] = useState()
  const [fontSize, setFontSize] = useState(16)
  const [lineHeight, setLineHeight] = useState(20)
  const [charSpacing, setCharSpacing] = useState(0)
  const [textAlign, setTextAlign] = useState('left')
  const [textColor, setTextColor] = useState(false)
  const [colorPicker, setColorPicker] = useState('rgba(0, 0, 0, 1)')
  const [diameter, setDiameter] = useState(500)
  const [kerning, setKerning] = useState(0)
  const [flipped, setFlipped] = useState(false)
  const [content, setContent] = useState()

  const getActiveStyle = (styleName) => {
    if (activeSelection.getSelectionStyles && activeSelection.isEditing) {
      let styles = activeSelection.getSelectionStyles()
      if (styles.find(o => o[styleName] === '')) {
        return ''
      }

      return styles[0][styleName]
    }

    return activeSelection[styleName] || ''
  }


  const setActiveStyle = (styleName, value) => {
    if (activeSelection.setSelectionStyles && activeSelection.isEditing && styleName !== 'fontSize') {
      let style = {}
      style[styleName] = value;
      activeSelection.setSelectionStyles(style)
      activeSelection.setCoords()
    } else {
      activeSelection.set(styleName, value)
    }
    canvas.renderAll()
    
    if(window.is_iframe)
      window['updateCanvas']();
  }


  const toggleBold = () => {
    setActiveStyle(
      'fontWeight',
      getActiveStyle('fontWeight') === 'bold' ? '' : 'bold'
    )
    canvas.trigger('object:modified')
  }


  const toggleItalic = () => {
    setActiveStyle(
      'fontStyle',
      getActiveStyle('fontStyle') === 'italic' ? '' : 'italic'
    )
    canvas.trigger('object:modified')
  }


  const toggleUnderline = () => {
    setActiveStyle('underline', !getActiveStyle('underline'))
    canvas.trigger('object:modified')
  }


  const toggleLinethrough = () => {
    setActiveStyle('linethrough', !getActiveStyle('linethrough'))
    canvas.trigger('object:modified')
  }


  const toggleSubscript = () => {
    if (getActiveStyle('deltaY') > 0) {
      setActiveStyle('fontSize', undefined)
      setActiveStyle('deltaY', undefined)
    } else {
      activeSelection.setSubscript()
      canvas.renderAll()
    }

    canvas.trigger('object:modified')
  }


  const toggleSuperscript = () => {
    if (getActiveStyle('deltaY') < 0) {
      setActiveStyle('fontSize', undefined)
      setActiveStyle('deltaY', undefined)
    } else {
      activeSelection.setSuperscript()
      canvas.renderAll()
    }

    canvas.trigger('object:modified')
  }


  const handleFamilyChange = (e) => {
    setFontFamily(e.target.value);
    setActiveStyle('fontFamily', e.target.value);

    var elem = document.querySelector('#selectFont option[value='+e.target.value+']');

    fabric.fontPaths[e.target.value] = 'https://localhost:3000/fonts/' + elem.getAttribute("data-path") + 
                                      e.target.value + '.' + elem.getAttribute("data-extension");

    //console.log(Array.from(document.fonts))

    //console.log(document.querySelector('#selectFont option[value='+e.target.value+']'))
    //console.log(e.target.value);
    //console.log(window.fabricCanvas.getActiveObject())

    canvas.trigger('object:modified')
  }


  const handleSizeChange = (action, amount) => {
    let size = 0

    if (action === 'decrease') {
      size = fontSize === 0 ? 0 : fontSize - 1
    }

    if (action === 'increase') {
      size = fontSize + 1
    }

    if (action === 'change') {
      size = parseInt(amount)
      if (!Number.isInteger(size)) return
    }

    setFontSize(size)
    setActiveStyle('fontSize', size)
    canvas.trigger('object:modified')
  }


  const handleLineHeightChange = (action, amount) => {
    let heightInPx = 0
    let heightInUnit = 0

    if (action === 'decrease') {
      heightInPx = lineHeight === 0 ? 0 : lineHeight - 1
    }

    if (action === 'increase') {
      heightInPx = lineHeight + 1
    }

    if (action === 'change') {
      heightInPx = parseInt(amount)
      if (!Number.isInteger(heightInPx)) return
    }

    heightInUnit = heightInPx / fontSize

    setLineHeight(heightInPx)
    setActiveStyle('lineHeight', heightInUnit)
    canvas.trigger('object:modified')
  }


  const handleCharSpaceChange = (action, amount) => {
    let spaceInPx = 0
    let spaceInUnitPiece = 0

    if (action === 'decrease') {
      spaceInPx = charSpacing - 1
    }

    if (action === 'increase') {
      spaceInPx = charSpacing + 1
    }

    if (action === 'change') {
      spaceInPx = parseInt(amount)
      if (!Number.isInteger(spaceInPx)) return
    }

    spaceInUnitPiece = spaceInPx / fontSize * 1000

    setCharSpacing(spaceInPx)
    setActiveStyle('charSpacing', spaceInUnitPiece)
    canvas.trigger('object:modified')
  }

  const handleTextAlignChange = (e) => {
    setTextAlign(e.target.value)
    setActiveStyle('textAlign', e.target.value)
    canvas.trigger('object:modified')
  }


  const handleColorChange = (color) => {
    setTextColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)
    setActiveStyle('fill', `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)

    delayedAction(1200, () => {
      canvas.trigger('object:modified')
    })
  }

  const textCurvedDiameter = (action, amount) => {

    let size = 0

    if (action === 'decrease') {
      size = diameter === 0 ? 0 : diameter - 100
    }

    if (action === 'increase') {
      size = diameter + 100
    }

    if (action === 'change') {
      size = parseInt(amount)
      if (!Number.isInteger(size)) return
    }

    setDiameter(size);

    if(diameter>= 100){
      activeSelection.set({
        diameter: diameter,
      });
      canvas.renderAll();
    }
    canvas.trigger('object:modified')
  }

  const textCurvedKerning = (action, amount) => {

    let size = 0

    if (action === 'decrease') {
      size = kerning === 0 ? 0 : kerning - 1
    }

    if (action === 'increase') {
      size = kerning + 1
    }

    if (action === 'change') {
      size = parseInt(amount)
      if (!Number.isInteger(size)) return
    }

    setKerning(size);

    activeSelection.set({
      kerning: kerning,
    });
    canvas.renderAll();
    canvas.trigger('object:modified')
  }

  const textCurvedFlipped = (event) => {
    setFlipped(event.target.checked);
    activeSelection.set({
      flipped: !flipped,
    });
    canvas.renderAll();
    canvas.trigger('object:modified')
  }

  const textCurvedContent = () => {

    setContent($("#content-curved-text").val())

    var textbox = new fabric.TextCurved(__($("#content-curved-text").val()), {
      left: activeSelection.left,
      top: activeSelection.top,
      width: activeSelection.width,
      fontSize: activeSelection.fontSize,
      fontFamily:  activeSelection.fontFamily,
      diameter: activeSelection.diameter,
      kerning: activeSelection.kerning,
      flipped: activeSelection.flipped
    });

    canvas.remove(activeSelection);
    canvas.add(textbox).setActiveObject(textbox)
    textbox.setControlsVisibility({ 'mb': false });

    canvas.renderAll();
    canvas.trigger('object:modified')
  }

  // on load get active selection font settings
  useEffect(() => {
    if (!activeSelection) return //|| !activeSelection.getSelectionStyles

    setFontFamily(activeSelection.fontFamily)
    setFontSize(activeSelection.fontSize)
    setDiameter(activeSelection.diameter)
    setKerning(activeSelection.kerning)
    setFlipped(activeSelection.flipped)
    setContent(activeSelection.text)
    $("#content-curved-text").val(activeSelection.text)

    let lineHeightInPx = Math.floor(fontSize * activeSelection.lineHeight)
    setLineHeight(lineHeightInPx)

    let spaceInPx = Math.round(activeSelection.charSpacing / 1000 * fontSize)
    setCharSpacing(spaceInPx)

    setTextAlign(activeSelection.textAlign)

    setTextColor(activeSelection.fill)

    if(activeSelection.type == "textbox"){
      $(".curved").css("display", "none");
    }else{
      $(".curved").css("display", "inline-block");
    }

  }, [activeSelection, fontSize])



  return (
    <>

      <div className="setting flex flex small-buttons">
        <Button handleClick={ () => toggleBold() } name="bold" title={__('Negrito')}><IconTextBold /></Button>
        <Button handleClick={ () => toggleItalic() } name="italic" title={__('Itálico')}><IconTextItalic /></Button>
        <Button handleClick={ () => toggleUnderline() } name="underline" title={__('Underline')}><IconTextUnderline /></Button>
        <Button handleClick={ () => toggleLinethrough() } name="linethrough" title={__('Linethrough')}><IconTextLineThrough /></Button>
        {/**<Button handleClick={ () => toggleSubscript() } name="subscript" title={__('Subscript')}><IconTextSub /></Button>
        <Button handleClick={ () => toggleSuperscript() } name="superscript" title={__('Superscript')}><IconTextSup /></Button> */}
      </div>
      <div>&nbsp;</div>


      <div className="setting">
        <div className="label lang" data-en="" data-pt="Familia da fonte" data-es="Familia tipográfica">{__('Font Family')}</div><div className="function">
          <select id="selectFont" value={fontFamily} onChange={(e) => handleFamilyChange(e)}>
            <option value=""></option>
            <option value="'Open Sans', sans-serif">Open Sans</option>
            <option value="'Oswald', sans-serif">Oswald</option>
            <option value="'Playfair Display', serif">Playfair Display</option>
            <option value="'Cormorant Garamond', serif">Cormorant Garamond</option>
            <option value="Impact, Charcoal, sans-serif">Impact</option>
            <option value="'Lucida Console', Monaco, monospace">Lucida Console</option>
            <option value="'Comic Sans MS', 'Comic Sans', cursive, sans-serif">Comic Sans</option>
            <option value="'Dancing Script', cursive">Dancing Script</option>
            <option value="'Indie Flower', cursive">Indie Flower</option>
            <option value="'Amatic SC', cursive">Amatic SC</option>
            <option value="'Permanent Marker', cursive">Permanent Marker</option>

            <option data-path="fonts/NeueMontreal-BoldItalic/" data-extension="otf" className="font1" value="'NeueMontreal-BoldItalic'">NeueMontreal-BoldItalic</option>

            <option data-path="fonts/" data-extension="otf" className="font2" value="aAngkatanBersenjata">aAngkatanBersenjata</option>
            <option data-path="fonts/" data-extension="otf" className="font3" value="aAlloyInk">aAlloyInk</option>
            <option data-path="fonts/" data-extension="otf" className="font4" value="aAbstractGroovy">aAbstractGroovy</option>
            <option data-path="fonts/" data-extension="otf" className="font5" value="aAnotherTag">aAnotherTag</option>
            <option data-path="fonts/" data-extension="otf" className="font6" value="aAntaraDistance">aAntaraDistance</option>
            <option data-path="fonts/" data-extension="ttf" className="font7" value="aArigatouGozaimasu">aArigatouGozaimasu</option>
            <option data-path="fonts/" data-extension="otf" className="font8" value="aAttackGraffiti">aAttackGraffiti</option>
            <option data-path="fonts/" data-extension="otf" className="font9" value="aAutobusOmnibus">aAutobusOmnibus</option>
            <option data-path="fonts/" data-extension="otf" className="font10" value="Alphakind">Alphakind</option>
            <option data-path="fonts/" data-extension="otf" className="font11" value="AromiaScript-Black_DONATIONWARE">AromiaScript-Black_DONATIONWARE</option>
            <option data-path="fonts/" data-extension="otf" className="font12" value="Atmos">Atmos</option>
            <option data-path="fonts/" data-extension="ttf" className="font13" value="AURORA-Regular">AURORA-Regular</option>
            <option data-path="fonts/" data-extension="otf" className="font14" value="Automobile Contest">Automobile Contest</option>
            <option data-path="fonts/" data-extension="otf" className="font15" value="Bekasi">Bekasi</option>
            <option data-path="fonts/" data-extension="ttf" className="font16" value="BrazierFlame">BrazierFlame</option>
            <option data-path="fonts/" data-extension="otf" className="font17" value="CastawayFill v2">CastawayFill v2</option>
            <option data-path="fonts/" data-extension="ttf" className="font18" value="Catamaran">Catamaran</option>
            <option data-path="fonts/" data-extension="ttf" className="font19" value="Coconut House">Coconut House</option>
            <option data-path="fonts/" data-extension="otf" className="font20" value="FatMax-Regular">FatMax-Regular</option>
            <option data-path="fonts/" data-extension="otf" className="font21" value="FREEZONE">FREEZONE</option>
            <option data-path="fonts/" data-extension="otf" className="font22" value="Grumaz-Regular">Grumaz-Regular</option>
            <option data-path="fonts/" data-extension="ttf" className="font23" value="KawitFree-CndItalic">KawitFree-CndItalic</option>
            <option data-path="fonts/" data-extension="ttf" className="font24" value="Lavindir">Lavindir</option>
            <option data-path="fonts/" data-extension="ttf" className="font25" value="LTBulletin-Medium">LTBulletin-Medium</option>
            <option data-path="fonts/" data-extension="otf" className="font26" value="LullabiestByHttpsgoths-Regular">LullabiestByHttpsgoths-Regular</option>
            <option data-path="fonts/" data-extension="ttf" className="font27" value="MakerVan-Regular">MakerVan-Regular</option>
            <option data-path="fonts/" data-extension="ttf" className="font28" value="NCS Rogueland Slab Bold">NCS Rogueland Slab Bold</option>
            <option data-path="fonts/" data-extension="ttf" className="font29" value="Number9">Number9</option>
            <option data-path="fonts/" data-extension="otf" className="font30" value="PixoRetoSP-Regular">PixoRetoSP-Regular</option>
            <option data-path="fonts/" data-extension="otf" className="font31" value="Rehat">Rehat</option>
            <option data-path="fonts/" data-extension="ttf" className="font32" value="Shock">Shock</option>
            <option data-path="fonts/" data-extension="ttf" className="font33" value="Talisman">Talisman</option>
            <option data-path="fonts/" data-extension="ttf" className="font34" value="teefont">teefont</option>
            <option data-path="fonts/" data-extension="otf" className="font35" value="WagoonBoldInline">WagoonBoldInline</option>

            <option data-path="fonts/fontsFutebol/" data-extension="otf" className="font36" value="BaysideFont-Regular-Demo">BaysideFont-Regular-Demo</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font37" value="BEST-IN-CLASS">BEST-IN-CLASS</option>
            <option data-path="fonts/fontsFutebol/" data-extension="otf" className="font38" value="CoubraFont-Demo">CoubraFont-Demo</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font39" value="Fut-1">Fut-1</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font40" value="Fut1">Fut1</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font41" value="Fut-3">Fut-3</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font42" value="fut-4">fut-4</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font43" value="fut-5<">fut-5</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font44" value="fut-6">fut-6</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font45" value="Fut-7">Fut-7</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font46" value="fut-8">fut-8</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font47" value="fut-9">fut-9</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font48" value="fut-10">fut-10</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font49" value="fut-11">fut-11</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font50" value="fut-12">fut-12</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font51" value="fut-13">fut-13</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font52" value="fut-14">fut-14</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font53" value="fut-15">fut-15</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font54" value="fut-16">fut-16</option>
            <option data-path="fonts/fontsFutebol/" data-extension="otf" className="font55" value="HighriseFont-Bold-Demo">HighriseFont-Bold-Demo</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font56" value="KAMIKZOM">KAMIKZOM</option>
            <option data-path="fonts/fontsFutebol/" data-extension="otf" className="font57" value="Miamagon">Miamagon</option>
            <option data-path="fonts/fontsFutebol/" data-extension="otf" className="font58" value="NewakeFont-Demo">NewakeFont-Demo</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font59" value="Russo_One">Russo_One</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font60" value="TESLA">TESLA</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font61" value="urae_nium">urae_nium</option>
            <option data-path="fonts/fontsFutebol/" data-extension="ttf" className="font62" value="Velocista-Demo-FFP">Velocista-Demo-FFP</option>
          
            {/**NEW FONTS */}
            <option data-path="novas/" data-extension="ttf" className="font63" value="Alemanha2014">Alemanha2014</option>
            <option data-path="novas/" data-extension="ttf" className="font64" value="ASRoma2017-18">ASRoma2017-18</option>
            <option data-path="novas/" data-extension="ttf" className="font65" value="AtleticoMadrid2013-2014">AtleticoMadrid2013-2014</option>
            <option data-path="novas/" data-extension="ttf" className="font66" value="Barcelona19-20">Barcelona19-20</option>
            <option data-path="novas/" data-extension="ttf" className="font67" value="Barcelona2013">Barcelona2013</option>
            <option data-path="novas/" data-extension="ttf" className="font68" value="BarcelonaNike2018-19">BarcelonaNike2018-19</option>
            <option data-path="novas/" data-extension="ttf" className="font69" value="bayern_munchen_2021_fonts593">bayern_munchen_2021_fonts593</option>
            <option data-path="novas/" data-extension="ttf" className="font70" value="Borussia2018">Borussia2018</option>
            <option data-path="novas/" data-extension="ttf" className="font71" value="BorussiaDortmund2018">BorussiaDortmund2018</option>
            <option data-path="novas/" data-extension="ttf" className="font72" value="BrasilQatar2022">BrasilQatar2022</option>
            <option data-path="novas/" data-extension="ttf" className="font73" value="Chelsea2019-2020">Chelsea2019-2020</option>
            <option data-path="novas/" data-extension="ttf" className="font74" value="ChelseaNike2017-18">ChelseaNike2017-18</option>
            <option data-path="novas/" data-extension="ttf" className="font75" value="ClubAmerica2019-20">ClubAmerica2019-20</option>
            <option data-path="novas/" data-extension="ttf" className="font76" value="ClubAmerica2021-2022">ClubAmerica2021-2022</option>
            <option data-path="novas/" data-extension="ttf" className="font77" value="Croatia2021Fonts593">Croatia2021Fonts593</option>
            <option data-path="novas/" data-extension="ttf" className="font78" value="EcuadorFont2022">EcuadorFont2022</option>
            <option data-path="novas/" data-extension="ttf" className="font79" value="france_2020_2021-Fonts593">france_2020_2021-Fonts593</option>
            <option data-path="novas/" data-extension="ttf" className="font80" value="Holanda2014">Holanda2014</option>
            <option data-path="novas/" data-extension="ttf" className="font81" value="Holanda2018">Holanda2018</option>
            <option data-path="novas/" data-extension="ttf" className="font82" value="Inglaterra2014-2015">Inglaterra2014-2015</option>
            <option data-path="novas/" data-extension="ttf" className="font83" value="Inglaterra2018-2019">Inglaterra2018-2019</option>
            <option data-path="novas/" data-extension="ttf" className="font84" value="inter_milanfont_2020-2021-Fonts593">inter_milanfont_2020-2021-Fonts593</option>
            <option data-path="novas/" data-extension="ttf" className="font85" value="InterMilan2011">InterMilan2011</option>
            <option data-path="novas/" data-extension="ttf" className="font86" value="InterMilan2018-19">InterMilan2018-19</option>
            <option data-path="novas/" data-extension="ttf" className="font87" value="Juventus2014-2015">Juventus2014-2015</option>
            <option data-path="novas/" data-extension="ttf" className="font88" value="Juventus2018-2019">Juventus2018-2019</option>
            <option data-path="novas/" data-extension="ttf" className="font89" value="Ligue1PSG2020-2021">Ligue1PSG2020-2021</option>
            <option data-path="novas/" data-extension="ttf" className="font90" value="ManchesterCity2021-2022">ManchesterCity2021-2022</option>
            <option data-path="novas/" data-extension="ttf" className="font91" value="ManchesterCity2017-2018">ManchesterCity2017-2018</option>
            <option data-path="novas/" data-extension="ttf" className="font92" value="ManchesterUnited2015-2016">ManchesterUnited2015-2016</option>
            <option data-path="novas/" data-extension="ttf" className="font93" value="ManchesterUnited2017-2018">ManchesterUnited2017-2018</option>
            <option data-path="novas/" data-extension="ttf" className="font94" value="Milan2015-2016">Milan2015-2016</option>
            <option data-path="novas/" data-extension="ttf" className="font95" value="Milan2017-2018">Milan2017-2018</option>
            <option data-path="novas/" data-extension="ttf" className="font96" value="MLSMajorLeagueSoccer2020">MLSMajorLeagueSoccer2020</option>
            <option data-path="novas/" data-extension="ttf" className="font97" value="Netherlands2018">Netherlands2018</option>
            <option data-path="novas/" data-extension="ttf" className="font98" value="Nigeria2020-2021">Nigeria2020-2021</option>
            <option data-path="novas/" data-extension="ttf" className="font99" value="Portugal2014-2015">Portugal2014-2015</option>
            <option data-path="novas/" data-extension="ttf" className="font100" value="PSGFont2021-2022">PSGFont2021-2022</option>
            <option data-path="novas/" data-extension="ttf" className="font101" value="RealMadrid2014-15">RealMadrid2014-15</option>
            <option data-path="novas/" data-extension="ttf" className="font102" value="RealMadrid2015">RealMadrid2015</option>
            <option data-path="novas/" data-extension="ttf" className="font103" value="RealMadrid2017-2018">RealMadrid2017-2018</option>
            <option data-path="novas/" data-extension="ttf" className="font104" value="RealMadrid2021-22">RealMadrid2021-22</option>
            <option data-path="novas/" data-extension="ttf" className="font105" value="RealMadridAdidas2018-19">RealMadridAdidas2018-19</option>
            <option data-path="novas/" data-extension="ttf" className="font106" value="reamadrid-2020-2021-Fonts593">reamadrid-2020-2021-Fonts593</option>
            <option data-path="novas/" data-extension="ttf" className="font107" value="Roma2015-16">Roma2015-16</option>
            <option data-path="novas/" data-extension="ttf" className="font108" value="RomaNike2018-19">RomaNike2018-19</option>
            <option data-path="novas/" data-extension="ttf" className="font109" value="south_korea_2020-2021-Fonts593">south_korea_2020-2021-Fonts593</option>
            <option data-path="novas/" data-extension="ttf" className="font110" value="TolucaUnder2018-2019">TolucaUnder2018-2019</option>
            <option data-path="novas/" data-extension="ttf" className="font111" value="USANationalTeam2020">USANationalTeam2020</option>
            <option data-path="novas/" data-extension="ttf" className="font112" value="USA-2018">USA-2018</option>
            <option data-path="novas/" data-extension="ttf" className="font113" value="vasco2016-2017">vasco2016-2017</option>
            <option data-path="novas/" data-extension="ttf" className="font114" value="wolfsburg_2020_2021_fonts593-Fonts593">wolfsburg_2020_2021_fonts593-Fonts593</option>
          </select>
        </div>
      </div>

      {/**<div className="setting curved">
        <div className="label">{__('Diametro')}</div><div className="function">
          <InputAmount unit="" value={diameter}
            handleDecrease={() => textCurvedDiameter('decrease')}
            handleChange={(e) => textCurvedDiameter('change', e.target.value)}
            handleIncrease={() => textCurvedDiameter('increase')}
            />
        </div>
      </div>

      <div className="setting curved">
        <div className="label">{__('Kerning')}</div><div className="function">
          <InputAmount unit="" value={kerning}
            handleDecrease={() => textCurvedKerning('decrease')}
            handleChange={(e) => textCurvedKerning('change', e.target.value)}
            handleIncrease={() => textCurvedKerning('increase')}
            />
        </div>
      </div>

      <div className="setting curved">
        <div className="label">{__('Espelhar')}</div><div className="function">
          <input type="checkbox" onChange={(e) => textCurvedFlipped(e)}></input>
        </div>
      </div> */}

      {/**<div className="setting curved">
        <div className="label">{__('Conteúdo')}</div><div className="function">
          <input type="text" id="content-curved-text"></input>
        </div>
      </div>

      <div className="setting curved">
        <div className="label">{__('Atualizar Texto')}</div><div className="function">
          <button onClick={textCurvedContent}>Confirm</button>
        </div>
      </div> */}

      {/**<div className="setting">
        <div className="label">{__('Tamanho')}</div><div className="function">
          <InputAmount unit="" value={fontSize}
            handleDecrease={() => handleSizeChange('decrease')}
            handleChange={(e) => handleSizeChange('change', e.target.value)}
            handleIncrease={() => handleSizeChange('increase')}
            />
        </div>
      </div>

      <div className="setting">
        <div className="label">{__('Altura da linha')}</div><div className="function">
          <InputAmount unit="" value={lineHeight}
            handleDecrease={() => handleLineHeightChange('decrease')}
            handleChange={(e) => handleLineHeightChange('change', e.target.value)}
            handleIncrease={() => handleLineHeightChange('increase')}
            />
        </div>
      </div>

      <div className="setting">
        <div className="label">{__('Espaço letras')}</div><div className="function">
          <InputAmount unit="" value={charSpacing}
            handleDecrease={() => handleCharSpaceChange('decrease')}
            handleChange={(e) => handleCharSpaceChange('change', e.target.value)}
            handleIncrease={() => handleCharSpaceChange('increase')}
            />
        </div>
      </div>

      <div className="setting">
        <div className="label">{__('Alinhamento')}</div><div className="function">
          <select value={textAlign} onChange={(e) => handleTextAlignChange(e)}>
            <option value="left">{__('Left')}</option>
            <option value="center">{__('Center')}</option>
            <option value="right">{__('Right')}</option>
            <option value="justify">{__('Justify')}</option>
          </select>
        </div>
      </div> */}


      {/**
       * <div className="setting">
        <div className="label">{__('Color')}</div>
        <div className="function">
          <div className="input-color">
            <div className="color" onClick={() => setColorPicker(!colorPicker)}>
              <div className="fill" style={{ backgroundColor: textColor }}></div>
            </div>
          </div>
        </div>
        <div className={colorPicker ? 'picker-holder visible' : 'picker-holder'}>
          <ChromePicker width="100%" color={textColor}
            onChange={handleColorChange} />
        </div>
      </div>
       */}
    </>
  )

}

export default SelectionTextSettings
