import React from 'react';
import './Layers.scss';
import { fabric } from 'fabric';
import __ from './../utils/translation';
import $ from "jquery";

const Layers = ({ canvas }) => {

  return (
    <>
        <p className="title lang" data-en="HIERARCHY" data-pt="HIERARQUIA" data-es="JERARQUÍA">{__('HIERARCHY')}</p>
        <div id="panel-graph">
                <button id="update-graph" className='lang' data-en="Update Hierarchy" data-pt="Atualizar Hierarquia" data-es="Actualizar Jerarquía">
                  Update Hierarchy
                </button>
              </div>
              <div className="folder-tree-wrapper">
                <ul className="folder-tree boxDrag" id="boxDrag"></ul>
                <div id="itemClipDrag" className="itemDrag itemClip hide"></div>
              </div>
    </>
  )

}

export default Layers

