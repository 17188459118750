import React, { useState, useEffect } from 'react';
import './Annotation.scss';
import __ from './../utils/translation';
import $ from "jquery";
import { downloadImage, downloadSVG } from '../utils/downloadImage';
import { applyZoom, zoomWithKeys, zoomWithMouse, zoomOptions } from '../utils/zoom';
import { ReactComponent as IconDownload2 } from '../icons/file_download.svg';

const AnimationState = ({ canvas }) => {

    

    return (
        <>
            <p className="title lang" data-en="ANIMATION" data-pt="ANIMAÇÂO" data-es="ANIMACIÓN">{__('ANIMATION')}</p>

            <div id="options-general">
                <div className='option-general'>
                    <span className='lang' data-en="State" data-pt="Estado" data-es="Estado">State:</span>
                    <div className='value-general'>
                        <input id="anim-state2" type='checkbox'></input>
                    </div>
                </div>
                <div className='option-general option-general-anim disabled'>
                    <span>Play/Pause:</span>
                    <div className='value-general'>
                        <input defaultChecked id="anim-play" type='checkbox'></input>
                    </div>
                </div>
                <div className='option-general option-general-anim disabled'>
                    <span className='lang' data-en="Speed" data-pt="Velocidade" data-es="Velocidad">Speed:</span>
                    <div className='value-general'>
                        <input step={0.01} id="anim-speed" type='range' min={0} max={1}></input>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default AnimationState