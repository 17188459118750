/*
 * Undo and Redo functions for FabricJs.
 */


export const undo = (canvas, history, setHistory) => {
  let updatedIndex = history.index === 0 ? 0 : history.index - 1
  //console.log(history)
  //canvas.loadFromJSON(history.states[updatedIndex], canvas.renderAll.bind(canvas))

  //canvas.setHeight(1024);
  //canvas.setWidth(2048);
  //canvas.renderAll();


  canvas.loadFromJSON(history.states[updatedIndex], function () {

    console.log(canvas._objects)
    /*
    for (var i = 0; i < canvas._objects.length; i++) {
      //if (canvas._objects[i].default) {
        canvas._objects[i].lockMovementX = true;
        canvas._objects[i].lockMovementY = true;
        canvas._objects[i].lockScalingX = true;
        canvas._objects[i].lockScalingY = true;
        canvas._objects[i].lockRotation = true;
        canvas._objects[i].hasControls = false;
     // }
    }*/

    canvas.renderAll.bind(canvas)
  });
  setHistory({
    index: updatedIndex,
    states: [...history.states]
  })
}


export const redo = (canvas, history, setHistory) => {
  if (history.index === history.states.length - 1) return

  let updatedIndex = history.index + 1
  canvas.loadFromJSON(history.states[updatedIndex], canvas.renderAll.bind(canvas))
  setHistory({
    index: updatedIndex,
    states: [...history.states]
  })
}
