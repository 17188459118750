import React, { useState, useEffect } from 'react';
import './Music.scss';
import __ from './../utils/translation';
import $ from "jquery";

const Music = ({ canvas }) => {


    return (
        <>
            <p className="title">{__('MUSIC')}</p>

            <div className="musics">

                

            </div>
        </>
    )
}

export default Music