import React, { useState, useEffect } from 'react';
import './Annotation.scss';
import __ from './../utils/translation';
import $ from "jquery";
import { downloadImage, downloadSVG } from '../utils/downloadImage';
import { applyZoom, zoomWithKeys, zoomWithMouse, zoomOptions } from '../utils/zoom';
import { ReactComponent as IconDownload2 } from '../icons/file_download.svg';

const LightState = ({ canvas }) => {

    

    return (
        <>
            <p className="title">{__('REFLECTOR')}</p>

            <div id="options-general">
                <div className='option-general'>
                    <span>State:</span>
                    <div className='value-general'>
                        <input id='light-state' type='checkbox'></input>
                    </div>
                </div>
                <div className='option-general'>
                    <span>Rotate:</span>
                    <div className='value-general'>
                        <input type='checkbox'></input>
                    </div>
                </div>
                <div className='option-general'>
                    <span>Shadows:</span>
                    <div className='value-general'>
                        <input type='checkbox'></input>
                    </div>
                </div>
                <div className='option-general'>
                    <span>Plane:</span>
                    <div className='value-general'>
                        <input type='checkbox'></input>
                    </div>
                </div>
                <div className='option-general'>
                    <span>Light Intensity:</span>
                    <div className='value-general'>
                        <input defaultValue={1} id='light-intensity' step={0.1} min={0} max={100} type='range'></input>
                    </div>
                </div>
                <div className='option-general'>
                    <span>Shadow Intensity:</span>
                    <div className='value-general'>
                        <input type='range'></input>
                    </div>
                </div>
                <div className='option-general'>
                    <span>Light Color:</span>
                    <div className='value-general'>
                        <input type='color'></input>
                    </div>
                </div>
                <div className='option-general'>
                    <button>Upload</button>
                </div>
            </div>
            
        </>
    )
}

export default LightState