import React from 'react';
import './ChangeSport.scss';
import __ from './../utils/translation';
import $ from "jquery";

import { ReactComponent as IconAnimation } from '../icons/animation_FILL0_wght400_GRAD0_opsz48.svg';

const Animation = ({ canvas }) => {

    $("body").on('click', '.anim-type', function () {
        $(".anim-type").css("text-decoration", "none");
        $(this).css("text-decoration", "underline");

        if($(this).attr("id") == "anim"){
            $(".anim").css("display", "flex");
            $(".pose").css("display", "none");
            $("#state-animation-parent").css("display", "flex");
        }else{
            $(".anim").css("display", "none");
            $(".pose").css("display", "flex");
            $("#state-animation-parent").css("display", "none");
        }

        if(window.gender == "female"){
            $(".female-not-pose").css("display", "none");
        }else{
            $(".female-not-pose").css("display", "flex");
        }

        /*$(".sport").css("display", "none");
        $("." + $(this).data("gender")).css("display", "flex");

        window.id_sport = $(this).attr("id");
        window.gender_sport = $(this).data("gender");*/
    });

    return (
        <>
            <p className="title">{__('ANIMATIONS')}</p>

            {/**<div className='options'>
                <p id="anim" data-gender="anim" className="subtitle anim-type">{__('Animações')}</p>
                <p id="pose" data-gender="pose" className="subtitle anim-type">{__('Poses')}</p> 
            </div> */}

            <div id="state-animation-parent">
                <button id="state-animation">Pause/Play Animations</button>
            </div>
            
            <div className="male-anim">

                <div className='anim male' data-gender="male" data-anim="0">
                    <IconAnimation />
                    <p>Nenhuma</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="1">
                    <IconAnimation />
                    <p>1</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="2">
                    <IconAnimation />
                    <p>2</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="3">
                    <IconAnimation />
                    <p>3</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="4">
                    <IconAnimation />
                    <p>4</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="5">
                    <IconAnimation />
                    <p>5</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="6">
                    <IconAnimation />
                    <p>6</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="7">
                    <IconAnimation />
                    <p>7</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="8">
                    <IconAnimation />
                    <p>8</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="9">
                    <IconAnimation />
                    <p>9</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="10">
                    <IconAnimation />
                    <p>10</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="11">
                    <IconAnimation />
                    <p>11</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="12">
                    <IconAnimation />
                    <p>12</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="13">
                    <IconAnimation />
                    <p>13</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="14">
                    <IconAnimation />
                    <p>14</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="15">
                    <IconAnimation />
                    <p>15</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="16">
                    <IconAnimation />
                    <p>16</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="17">
                    <IconAnimation />
                    <p>17</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="18">
                    <IconAnimation />
                    <p>18</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="19">
                    <IconAnimation />
                    <p>19</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="20">
                    <IconAnimation />
                    <p>20</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="21">
                    <IconAnimation />
                    <p>21</p>
                </div>
                <div className='anim male' data-gender="male" data-anim="22">
                    <IconAnimation />
                    <p>22</p>
                </div>
                {/**<div className='anim' data-gender="male" data-anim="23">
                    <IconAnimation />
                    <p>23</p>
                </div>
                <div className='anim' data-gender="male" data-anim="24">
                    <IconAnimation />
                    <p>24</p>
                </div>
                <div className='anim' data-gender="male" data-anim="25">
                    <IconAnimation />
                    <p>25</p>
                </div>
                <div className='anim' data-gender="male" data-anim="26">
                    <IconAnimation />
                    <p>26</p>
                </div>
                <div className='anim' data-gender="male" data-anim="27">
                    <IconAnimation />
                    <p>27</p>
                </div>
                <div className='anim' data-gender="male" data-anim="28">
                    <IconAnimation />
                    <p>28</p>
                </div>
                <div className='anim' data-gender="male" data-anim="29">
                    <IconAnimation />
                    <p>29</p>
                </div>
                <div className='anim' data-gender="male" data-anim="30">
                    <IconAnimation />
                    <p>30</p>
                </div>
                <div className='anim' data-gender="male" data-anim="31">
                    <IconAnimation />
                    <p>31</p>
                </div>
                <div className='anim' data-gender="male" data-anim="32">
                    <IconAnimation />
                    <p>32</p>
                </div>
                <div className='anim' data-gender="male" data-anim="33">
                    <IconAnimation />
                    <p>33</p>
                </div>
                <div className='anim' data-gender="male" data-anim="34">
                    <IconAnimation />
                    <p>34</p>
                </div>
                <div className='anim' data-gender="male" data-anim="35">
                    <IconAnimation />
                    <p>35</p>
                </div>
                <div className='anim' data-gender="male" data-anim="36">
                    <IconAnimation />
                    <p>36</p>
                </div>
                <div className='anim' data-gender="male" data-anim="37">
                    <IconAnimation />
                    <p>37</p>
                </div>
                <div className='anim' data-gender="male" data-anim="38">
                    <IconAnimation />
                    <p>38</p>
                </div> */}

                {/**-------------------------------------------------------- */}

                <div className='anim female' data-gender="female" data-anim="0">
                    <IconAnimation />
                    <p>Nenhuma</p>
                </div>
                <div className='anim female' data-gender="female" data-anim="1">
                    <IconAnimation />
                    <p>1</p>
                </div>
                <div className='anim female' data-gender="female" data-anim="2">
                    <IconAnimation />
                    <p>2</p>
                </div>
                <div className='anim female' data-gender="female" data-anim="3">
                    <IconAnimation />
                    <p>3</p>
                </div>
                <div className='anim female' data-gender="female" data-anim="4">
                    <IconAnimation />
                    <p>4</p>
                </div>
                <div className='anim female' data-gender="female" data-anim="5">
                    <IconAnimation />
                    <p>5</p>
                </div>
                <div className='anim female' data-gender="female" data-anim="6">
                    <IconAnimation />
                    <p>6</p>
                </div>
                <div className='anim female' data-gender="female" data-anim="7">
                    <IconAnimation />
                    <p>7</p>
                </div>
                <div className='anim female' data-gender="female" data-anim="8">
                    <IconAnimation />
                    <p>8</p>
                </div>
                <div className='anim female' data-gender="female" data-anim="9">
                    <IconAnimation />
                    <p>9</p>
                </div>
                <div className='anim female' data-gender="female" data-anim="10">
                    <IconAnimation />
                    <p>10</p>
                </div>
                {/**<div className='pose' data-gender="male" data-anim="2">
                    <IconAnimation />
                    <p>2</p>
                </div>
                <div className='pose' data-gender="male" data-anim="3">
                    <IconAnimation />
                    <p>3</p>
                </div>
                <div className='pose' data-gender="male" data-anim="4">
                    <IconAnimation />
                    <p>4</p>
                </div>
                <div className='pose' data-gender="male" data-anim="5">
                    <IconAnimation />
                    <p>5</p>
                </div>
                <div className='pose' data-gender="male" data-anim="6">
                    <IconAnimation />
                    <p>6</p>
                </div>
                <div className='pose' data-gender="male" data-anim="7">
                    <IconAnimation />
                    <p>7</p>
                </div>
                <div className='pose' data-gender="male" data-anim="8">
                    <IconAnimation />
                    <p>8</p>
                </div>
                <div className='pose' data-gender="male" data-anim="9">
                    <IconAnimation />
                    <p>9</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="10">
                    <IconAnimation />
                    <p>10</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="11">
                    <IconAnimation />
                    <p>11</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="12">
                    <IconAnimation />
                    <p>12</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="13">
                    <IconAnimation />
                    <p>13</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="14">
                    <IconAnimation />
                    <p>14</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="15">
                    <IconAnimation />
                    <p>15</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="16">
                    <IconAnimation />
                    <p>16</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="17">
                    <IconAnimation />
                    <p>17</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="18">
                    <IconAnimation />
                    <p>18</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="19">
                    <IconAnimation />
                    <p>19</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="20">
                    <IconAnimation />
                    <p>20</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="21">
                    <IconAnimation />
                    <p>21</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="22">
                    <IconAnimation />
                    <p>22</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="23">
                    <IconAnimation />
                    <p>23</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="24">
                    <IconAnimation />
                    <p>24</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="25">
                    <IconAnimation />
                    <p>25</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="26">
                    <IconAnimation />
                    <p>26</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="27">
                    <IconAnimation />
                    <p>27</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="28">
                    <IconAnimation />
                    <p>28</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="29">
                    <IconAnimation />
                    <p>29</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="30">
                    <IconAnimation />
                    <p>30</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="31">
                    <IconAnimation />
                    <p>31</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="32">
                    <IconAnimation />
                    <p>32</p>
                </div>
                <div className='pose female-not-pose' data-gender="male" data-anim="33">
                    <IconAnimation />
                    <p>33</p>
                </div> */}
            </div>
        </>
    )
}

export default Animation