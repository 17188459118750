import React from 'react';
import './SelectionSettings.scss';

import __ from './../utils/translation';

import SelectionColorSettings from './SelectionColorSettings';
import SelectionBorderSettings from './SelectionBorderSettings';
import SelectionTextSettings from './SelectionTextSettings';
import SelectionAlignSettings from './SelectionAlignSettings';
import SelectionObjectSettings from './SelectionObjectSettings';
import SelectionEffectSettings from './SelectionEffectSettings';
import SelectionControlsSettings from './SelectionControlsSettings';
//
import SelectionClipSettings from './SelectionClipSettings';
import $ from "jquery";

const SelectionSettings = ({ canvas, activeSelection }) => {

  $("#layersParent").css("display", "none");
  $("#patternsParent").css("display", "none");
  $("#colorsParent").css("display", "none");
  $("#UploadImage").css("display", "none");
  $("#TextBox").css("display", "none");
  //$(".toolbar button").removeClass("active");

  return (
    <>
      <p className="title lang" data-en="SELECTION SETUP" data-pt="CONFIGURAÇÃO DE SELEÇÃO" data-es="CONFIGURACIÓN DE SELECCIÓN">{__('SELECTION SETUP')}</p>

      {/**activeSelection.type !== 'image' &&
        activeSelection.type !== 'line' &&
        activeSelection.type !== 'activeSelection' &&
        activeSelection.type !== 'group' &&
        activeSelection.type !== 'textbox' && */}

    { !activeSelection.default &&
      <>
      <p className="subtitle lang" data-en="Object Options" data-pt="Opções do objeto" data-es="Opciones de objeto">{__('Object Options')}</p>
      <SelectionObjectSettings canvas={canvas} activeSelection={activeSelection} />
      </>
    }

      { (activeSelection.type === 'textbox' || activeSelection.type === "text-curved" || activeSelection.text) &&
        <>
          <p className="subtitle lang" data-en="Font Style" data-pt="Estilo de fonte" data-es="Estilo de fuente">{__('Font Style')}</p>
          <SelectionTextSettings canvas={canvas} activeSelection={activeSelection} />
        </>
      }

      { activeSelection.type !== 'group' && !activeSelection.default &&
        <>
          <p className="subtitle lang" data-en="Border" data-pt="Borda" data-es="Borde">{__('Border')}</p>
          <SelectionBorderSettings canvas={canvas} activeSelection={activeSelection} />
        </>
      }


    { !activeSelection.default &&
      <>
      <p className="subtitle lang" data-en="Alignment" data-pt="Alinhamento" data-es="Alineación">{__('Alignment')}</p>
      <SelectionAlignSettings canvas={canvas} activeSelection={activeSelection} />
      </>
    }

      {
        <>
        <p className="subtitle lang" data-en="Color Options" data-pt="Opções de cores" data-es="Opciones de color">{__('Color Options')}</p>
        <SelectionColorSettings canvas={canvas} activeSelection={activeSelection} />
        </>
      }


      {/**{activeSelection.type === 'image' &&
        <>
          <p className="subtitle">{__('Effects')}</p>
          <SelectionEffectSettings canvas={canvas} activeSelection={activeSelection} />
        </>
      } */}

      {
        <>
        <p className="subtitle">{__('Clip')}</p>
        <SelectionClipSettings canvas={canvas} activeSelection={activeSelection} />
        </>
      }

      {
        <>
        <p className="subtitle lang" data-en="Controls" data-pt="Controles" data-es="Control S">{__('Controls')}</p>
        <SelectionControlsSettings canvas={canvas} activeSelection={activeSelection} />
        </>
      }


    </>
  )
}

export default SelectionSettings
