import React, { useState, useEffect } from 'react';
import './Cloth.scss';
import __ from './../utils/translation';
import $ from "jquery";

import IconColth1 from '../cloth_fabric/thumbs/1.jpg';
import IconColth2 from '../cloth_fabric/thumbs/2.png';
import IconColth3 from '../cloth_fabric/thumbs/3.png';
import IconColth4 from '../cloth_fabric/thumbs/4.png';
import IconColth5 from '../cloth_fabric/thumbs/5.png';
import IconColth6 from '../cloth_fabric/thumbs/6.png';
import IconColth7 from '../cloth_fabric/thumbs/7.png';
import IconColth8 from '../cloth_fabric/thumbs/8.png';
import IconColth9 from '../cloth_fabric/thumbs/9.png';
import IconColth10 from '../cloth_fabric/thumbs/10.png';

import IconColth11 from '../cloth_fabric/thumbs/11.png';
import IconColth12 from '../cloth_fabric/thumbs/12.png';
import IconColth13 from '../cloth_fabric/thumbs/13.png';

import { Tabs, Tab } from './Tabs';
import { ChromePicker } from 'react-color';

const Cloth = ({}) => {

    const [drawSettings, setDrawSettings] = useState({
        duration: 1,
        time: 10,
        brilho: 0,
        metal: 0,
        scale: 0,
        color: 'rgba(255, 255, 255, 0)',
        camisa: true,
        short: true,
        meia: true,
        corpo: false,
        sapato: true,
        cabelo: true,
        mascara: true,
        oculos: true,
        hats: true,
        fone: true,
        polchete: true,
        morph: true,
        map: null
    })

    $("body").on('click', '.custom-type', function () {
        $(".custom-type").css("text-decoration", "none");
        $(this).css("text-decoration", "underline");

        $(".custom").css("display", "none");
        $("#" + $(this).data("type")).css("display", "block");

        if($(this).data("type") == "material"){
            $(".block-panel").css("opacity", "1");
        }else{
            $(".block-panel").css("opacity", "0.5");
        }
    });

    const handleBrilhoChange = (value) => {
        setDrawSettings({...drawSettings,
          brilho: value
        })
    
        window['updateRoughness'](value);
    }

    const handleMetalChange = (value) => {
        setDrawSettings({...drawSettings,
          metal: value
        })
    
        window['updateMetalness'](value);
    }

    const handleMapChange = (value) => {

        let reader = new FileReader()

        // handle image, read file, add to canvas
        /*reader.onload = (f) => {
            //f.target.result
            window['updateMap'](f.target.result);
            $("#map-mesh").val(''); 
            //$("#image-holder").append("<div data-type='"+file.type+"' class='img-holder' data-value='"+f.target.result+"' style='background-image: url("+f.target.result+")'><div class='remove_img_holder'>X</div><div class='crop_img_holder'>C</div></div>")
        }*/

        //reader.readAsDataURL(value.files[0])

        var fileName = URL.createObjectURL(value.files[0]);
        window['updateMap'](fileName);
        $("#map-mesh").val('');
        
        /*setDrawSettings({...drawSettings,
          metal: value
        })
    
        window['updateMetalness'](value);*/
    }

    const handleColorChange = (color) => {
        setDrawSettings({...drawSettings, color: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`})
        window['updateColorMaterial'](color);
    }

    window['colorMesh'] = function(color, metal, brilho){
        setDrawSettings({...drawSettings, color: `rgba(${color.r * 255}, ${color.g * 255}, ${color.b * 255}, 1)`, metal: metal, brilho: brilho})
    }
    

    return (
        <>
            <p className="title lang" data-en="CUSTOMIZE MATERIALS" data-pt="PERSONALIZAR MATERIAIS" data-es="PERSONALIZAR MATERIALES">{__('CUSTOMIZE MATERIALS')}</p>

            <div className='options'>
                <p id="custom-material" data-type="material" data-display="block" className="subtitle custom-type">{__('Material')}</p>
                <p id="custom-parts" data-type="fabric-options-parent" data-display="block"  className="subtitle custom-type lang" data-en="Fabric" data-pt="Tecido" data-es="Tela">{__('Fabric')}</p>
            </div>

            <div className="block-panel options">
                <span className='lang' data-en="To edit the material, double click on the part you want to select" data-pt="Para editar o material, clique duas vezes na peça que deseja selecionar" data-es="Para editar el material, haga doble clic en la parte que desea seleccionar">To edit the material, double click on the part you want to select</span>
                <button id="object-name">...</button>
            </div>

            <div id="material" className='custom'>
                <div className='thumb-background-color material' id="color-mesh">
                    <Tab title={__('Color fill')} name="color">
                        <ChromePicker width="100%" color={drawSettings.color} onChange={handleColorChange} />
                    </Tab>
                </div>
                <div className="setting material">
                    <div className="label lang" data-en="Shininess" data-pt="Brilho" data-es="Brillo">{__('Shininess')}</div><div className="function">
                    <input id="brilho-mesh" type="range" min="0" max="100" steps="1"
                        onChange={ (e) => handleBrilhoChange(e.target.value) }
                        value={ drawSettings.brilho } />
                    </div>
                </div>
                <div className="setting material">
                    <div className="label lang" data-en="Metalness" data-pt="Metalidade" data-es="metalidad">{__('Metalness')}</div><div className="function">
                    <input id="metal-mesh" type="range" min="0" max="100" steps="1"
                        onChange={ (e) => handleMetalChange(e.target.value) }
                        value={ drawSettings.metal } />
                    </div>
                </div>

                <div className="setting material">
                    <div className="label lang" data-en="Custom Map" data-pt="Mapa personalizado" data-es="Mapa personalizado">{__('Custom Map')}</div><div className="function">
                    <input id="map-mesh" type="file" accept='.jpg'
                        onChange={ (e) => handleMapChange(e.target) }
                    />
                    </div>
                </div>
            </div>

            <div id="fabric-options-parent" className='custom'>
                <div className='options'>
                    <button id="add-fabric" className='lang' data-en="Add Texture" data-pt="Adicionar Textura" data-es="Agregar textura">Add Texture</button>
                    <button id="remove-fabric" className='lang' data-en="Remove Texture" data-pt="Remover Textura" data-es="Quitar textura">Remove Texture</button>
                </div>

                <div className='options'>
                    <div>
                        <span>Repeat: </span>
                        <input step={1} min={1} max={100} id="input-repeat-fabric" type="number" defaultValue={30}></input>
                        <button id="update-fabric">Update</button>
                    </div>
                </div>

                <div id="fabric-options">
                    <div className='' id="tecido">
                        <div data-repeat="1" className="tecido" data-nam="0"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth1 + ")"}} className="tecido" data-nam="1"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth2 + ")"}} className="tecido" data-nam="2"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth3 + ")"}} className="tecido" data-nam="3"></div>
                        <div data-repeat="1" data-opacity="1" style={{backgroundImage:  "url(" + IconColth4 + ")"}} className="tecido" data-nam="4"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth5 + ")"}} className="tecido" data-nam="5"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth6 + ")"}} className="tecido" data-nam="6"></div>
                        <div data-repeat="1" data-opacity="1" style={{backgroundImage:  "url(" + IconColth7 + ")"}} className="tecido" data-nam="7"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth8 + ")"}} className="tecido" data-nam="8"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth9 + ")"}} className="tecido" data-nam="9"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth10 + ")"}} className="tecido" data-nam="10"></div>
                    
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth11 + ")"}} className="tecido" data-nam="11"></div>
                        <div data-repeat="1" style={{backgroundImage:  "url(" + IconColth12 + ")"}} className="tecido" data-nam="12"></div>
                        <div data-repeat="1" data-opacity="1" style={{backgroundImage:  "url(" + IconColth13 + ")"}} className="tecido" data-nam="13"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cloth