import React from 'react';
import { fabric } from 'fabric';

import Button from './Button';

const SelectionClipSettings = ({ canvas, activeSelection }) => {

  
  return (
    <div>
        <p className='lang' data-en="To apply clip path, select 2 objects and click the button below,
          the second object will be used as a mask for the first object." data-pt="Para aplicar o clip path, selecione 2 objetos e clique no botão abaixo,
          o segundo objeto será usado como máscara para o primeiro objeto." data-es="Para aplicar Clip Path, seleccione 2 objetos y haga clic en el botón de abajo,
          el segundo objeto se utilizará como máscara para el primer objeto.">To apply clip path, select 2 objects and click the button below,
          the second object will be used as a mask for the first object.</p>
        <div id="clip-options">
            <button id="apply-clip-path" className='lang' data-en="Apply Clip Path" data-pt="Aplicar Clip Path" data-es="Aplicar Clip Path">Apply Clip Path</button>
            <button id="remove-clip-path" className='lang' data-en="Remove Clip Path" data-pt="Remover Clip Path" data-es="Eliminar Clip Path">Remove Clip Path</button>
        </div>
    </div>
  )

}

export default SelectionClipSettings
