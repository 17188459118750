/*
 * Demo content for FabricJS.
 */
import $ from "jquery";


const demoContent = (canvas, fabric) => {

  /*fabric.Image.fromURL('./test2.png', function(oImg) {
    oImg.set({ left: 0, top: 0 })
    canvas.add(oImg);
  }, { crossOrigin: 'Anonymous' });*/

  window.isMobile = false

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    window.isMobile = true
    /*$(".toolbar").css("width", "100%");
    $(".toolbar").css("height", "54px");
    $(".toolbar").css("display", "flex");
    $(".toolbar").css("bottom", "0px");
    $(".toolbar").css("top", "auto");
    $(".toolbar").css("position", "absolute");
    $(".toolbar").css("min-height", "auto");
    $(".toolbar").css("overflow-x", "scroll");

    $(".toolpanel").css("width", "100%");
    $(".toolpanel").css("height", "36%");
    $(".toolpanel").css("left", "0px");
    $(".toolpanel").css("bottom", "-29%");
    $(".toolpanel").css("top", "auto");

    $(".minimizer").css("right", "46%");
    $(".minimizer").css("transform", "rotate(-90deg)");
    $(".minimizer").css("top", "-48px");

    $("#svg-editor-state").css("display", "none");*/

    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      $("#init-ar").css("display", "none");
    }
  }else{
    
    $("#init-ar").css("display", "none");
  }

  //
  var backLoaded = false;
  window.editingBackground = false;

  //$("body").on('click', '#edit-background', function () {
  $('#edit-background').unbind().click(function (e) {

    window.editingBackground = !window.editingBackground;

    if (window.editingBackground) {
      //
      for(var i=0; i<window.scene.children.length;i++){
        window.scene.children[i].visible = false;
      }
      //
      //
      window.canvasDefault = JSON.stringify(window.fabricCanvas.toObject(['default', 'id', 'scaleX', 'scaleY']));
      //
      window.fabricCanvas.clear();
      window.fabricCanvas.setHeight(300);
      window.fabricCanvas.setWidth(600);
      //
      /*if (!backLoaded) {
        window.fabric.Image.fromURL("../background.jpg", (img) => {
          img.set({
            left: 0,
            top: 0
          });
          //img.scaleToHeight(3000);//2160x4096
          img.scaleToWidth(6000);
          window.fabricCanvas.add(img);

          img.lockMovementX = true;
          img.lockMovementY = true;
          img.lockScalingX = true;
          img.lockScalingY = true;
          img.lockRotation = true;
          img.hasControls = false;
          img.default = true;

          window.fabricCanvas.renderAll();
          window.fabricCanvas.trigger('object:modified');
        });
      }*/
      //

      if (window.canvasBackground) {
        window.fabricCanvas.loadFromJSON(window.canvasBackground, function () {
          window.fabricCanvas.renderAll()
        });
      } else {
        window.fabricCanvas.renderAll();
      }
      //
      backLoaded = true;
    } else {
      //
      for(var i=0; i<window.scene.children.length;i++){
        window.scene.children[i].visible = true;
      }
      //
      //
      window.canvasBackground = JSON.stringify(window.fabricCanvas.toObject(['default', 'id', 'scaleX', 'scaleY']));
      //
      window.fabricCanvas.clear();
      window.fabricCanvas.setHeight(600);
      window.fabricCanvas.setWidth(600);
      window.fabricCanvas.loadFromJSON(window.canvasDefault, function () {
        window.fabricCanvas.renderAll();
        for (var i = 0; i < window.fabricCanvas._objects.length; i++) {
          if (window.fabricCanvas._objects[i].default) {
            window.fabricCanvas._objects[i].lockMovementX = true;
            window.fabricCanvas._objects[i].lockMovementY = true;
            window.fabricCanvas._objects[i].lockScalingX = true;
            window.fabricCanvas._objects[i].lockScalingY = true;
            window.fabricCanvas._objects[i].lockRotation = true;
            window.fabricCanvas._objects[i].hasControls = false;
          }
        }
      });
    }

  });
}


export default demoContent
