/*
 * Downloads images in browser
 */

var downloading = false;

const downloadImage = (data, extension, mimeType) => {

  if (!downloading) {

    
    const imageData = data.toString().replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
    const byteCharacters = atob(imageData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], {
      type: mimeType + ';base64'
    });
    const fileURL = window.URL.createObjectURL(file);

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file);
      return;
    }
    const link = document.createElement('a');
    link.href = document.getElementById("c").toDataURL();;
    link.download = 'vetor-jpg.' + extension;

    if(window.ziping){
      window.zip.file(window.pose + "vector.jpg", file);
      window["stepsDownloadAll"]();
    }else{
      downloading = true;
      link.dispatchEvent(new MouseEvent('click'));
    }
    
    setTimeout(() => {
      // for Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(fileURL);
      downloading = false;
    }, 60);
  }
}

const downloadSVG = (SVGmarkup) => {

  if (!downloading) {
    
    
    const url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(SVGmarkup);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'vetor-svg.svg';
    //link.dispatchEvent(new MouseEvent('click'));

    /*const imageData = url.toString().replace(/^data:image\/(svg+xml);base64,/, '');
    const byteCharacters = atob(imageData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], {
      type: mimeType + ';base64'
    });*/


    if(window.ziping){
      window.zip.file(window.pose + "vector.svg", SVGmarkup);
      window["stepsDownloadAll"]();
    }else{
      downloading = true;
      link.dispatchEvent(new MouseEvent('click'));
    }

    setTimeout(() => {
      // for Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
      downloading = false;
    }, 60);
  }
}


export {
  downloadImage,
  downloadSVG
}
